﻿import React, { Component } from 'react';

import Input from "../../../shared/components/Input";
import Checkbox from "../../../shared/components/Checkbox";
import TranslationService from "../../../shared/services/TranslationService";
import ValidationService from "../../../shared/services/ValidationService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty
};

class BarkerComponent extends Component {
	
	constructor() {
		super();
		this.state = {
			plateNumber: {
				value: '',
				error: ''
			},
			ssnOrgNr: {
				value: '',
				error: ''
			},
			privateCompany: {
				value: false,
				error: ''
			},
			cssClasses: '',
			translations: []
		};

		this.postData = {
			registrationNumber: '',
			customerId: '',
			privateCompany: false,
			brand: 'LQ'
		}

		this.submitFired = false;
	}

	componentDidMount() {
		setTimeout(() => {
			$('.hero-content').addClass( 'show' );
		}, 500);
	}

	handlePlateNumberChange = e => {
		let newState = $.extend({}, this.state.plateNumber);
		newState.value = e.target.value.toLocaleUpperCase();
		this.postData.registrationNumber = e.target.value;
		this.setState({plateNumber: newState}, () => {
			this.handlePlateNumberValidation();
		});
	}

	handleSsnOrgNrChange = e => {
		let newState = $.extend({}, this.state.ssnOrgNr);
		newState.value = e.target.value;
		this.postData.customerId = e.target.value;
		this.setState({ssnOrgNr: newState}, () => {
			this.handleSsnOrgNrValidation();
		});
	}

	handlePrivateCompanyChange = e => {
		let newState = $.extend({}, this.state.privateCompany);
		newState.value = e.target.checked;
		this.postData.privateCompany = e.target.checked;
		this.setState({ privateCompany: newState });
	}

	// Handle validation
	handlePlateNumberValidation = () => {
		if (this.submitFired) {
			const rules = {
				plateNumber: 'required|plateNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/startpage/barker/regnrerror'),
				plateNumber: TranslationService.getTranslation('/startpage/barker/regnrwrongformaterror')
			}

			let stateObject = ValidationService.validate(this.state.plateNumber, rules, customErrorMessages);
			this.setState({plateNumber: stateObject});
		}
	}

	handleSsnOrgNrValidation = () => {
		if (this.submitFired) {
			const rules = {
				ssnOrgNr: 'required|orgSsn'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/startpage/barker/pnrorgnrerror'),
				orgSsn: TranslationService.getTranslation('/startpage/barker/pnrorgnrerror')
			}

			let stateObject = ValidationService.validate(this.state.ssnOrgNr, rules, customErrorMessages);
			this.setState({ssnOrgNr: stateObject});
		}
	}

	handleSubmit = () => {
		this.submitFired = true;
		this.handlePlateNumberValidation();
		this.handleSsnOrgNrValidation();

		if (!this.hasErrorInForm()) {
			let linkerParam = '';
			if (window.ga) {
				let trackers = window.ga.getAll();

				if (trackers.length > 0) {
					linkerParam = trackers[0].get('linkerParam');
				}
			}

			let url = window.barkerAppObject.redirectUrl + '?' + linkerParam + '&affiliate=Lexus';
			if (window.barkerAppObject.redirectUrl.indexOf('#') > -1) {
				let urlStrings = window.barkerAppObject.redirectUrl.split('#');
				if (urlStrings.length > 1) {
					url = urlStrings[0];
					url += '?' + linkerParam + '&affiliate=Lexus' + '#';
					url += urlStrings[1];
				}
			}

			this.postData.redirectUrl = url;

			let postObject = {
				url: window.barkerAppObject.apiLexusCookieUrl,
				form: this.postData
			}
			BM.forms.createAndPostForm(postObject);
		}
	}

	hasErrorInForm() {
		var result = false;
		_.forOwn(this.state, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				result = true;
			}
		});

		return result;
	}

	getPulLink = () => {
		return window.barkerAppObject.pulLink;
	}

	render() {
		return (
			<div className="hero-content hero-content-left">
				<div className="hero-content-inner hero-content-inner-white">
					<div className="row">
						<div className="col">
							<h2 className="mb-1">{window.barkerAppObject.subHeading}</h2>
							<h1>{window.barkerAppObject.heading}</h1>
						</div>
					</div>

					<div className="row pb-lg-1">
						<div className="col">
							<div className="form-group grey-bg">
								<Input label={TranslationService.getTranslation('/startpage/barker/regnr')}
								       id="plateNumber"
								       value={this.state.plateNumber.value}
								       error={this.state.plateNumber.error}
								       plateInput={true}
								       maxLength={6}
								       link={{
								           url: this.getPulLink(),
							       		   text: TranslationService.getTranslation('/startpage/barker/userinfo')
								       }}
								       placeholder={TranslationService.getTranslation('/placeholders/regnr')}
								       onChange={this.handlePlateNumberChange}
								       onValidate={this.handlePlateNumberValidation}
								       onKeyPress={this.handleSubmit}/>
							</div>
						</div>
					</div>

					<div className="row pb-lg-1">
						<div className="col">
							<Input label={TranslationService.getTranslation('/startpage/barker/pnrorgnr')}
							       id="plateNumber"
							       formGroupClass={`mb-05`}
							       value={this.state.ssnOrgNr.value}
							       error={this.state.ssnOrgNr.error}
							       placeholder={TranslationService.getTranslation('/placeholders/orgnr')}
							       onChange={this.handleSsnOrgNrChange}
							       onValidate={this.handleSsnOrgNrValidation}
							       onKeyPress={this.handleSubmit}/>
						</div>
					</div>

					<div className="row pb-15">
						<div className="col-12">
							<Checkbox id="privateCompany"
							          text={TranslationService.getTranslation('/startpage/barker/privatecompany')}
							          value={this.state.privateCompany.value}
							          error={this.state.privateCompany.error}
							          onChange={this.handlePrivateCompanyChange}
							          onKeyPress={this.handleSubmit}/>
						</div>
					</div>

					<div className="row">
						<div className="col-12 text-lg-left text-center">
							<button type="submit" 
							        className="btn btn-primary"
							        onClick={this.handleSubmit}>
								{TranslationService.getTranslation('/startpage/barker/button')}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default BarkerComponent;