﻿var $ = require("jquery");

import 'popper.js/dist/umd/popper.js';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery-validation/dist/jquery.validate.js';
import 'jquery-ajax-unobtrusive/dist/jquery.unobtrusive-ajax.js';
import 'jquery-validation-unobtrusive/dist/jquery.validate.unobtrusive.js';
import 'eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker.js';

// Export to global scope
exports.forms = require('../shared/forms.js');
exports.reportDamageFormPage = require('../shared/reportDamageFormPage.js');
exports.functions = require('../shared/functions.js');
exports.utilities = require('../shared/utilities.js');
exports.cookieHandler = require('../shared/cookieHandler.js');

// Custom js
import '../shared/utilities.js';
import '../shared/carriageWarrantyBlockApp/App.js';
import '../shared/contactUsFormBlockApp/App.js';
import '../shared/reportDamageFormApp/App.js';
import '../shared/reportDamageBarkerApp/App.js';
import '../shared/videoBlock.js';
import '../shared/tableBlock.js';
import '../shared/focus.js';
import './navigation.js';
import './barkerApp/App.js'