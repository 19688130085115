﻿var $ = require("jquery");
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Input from "../../../shared/components/Input";
import RadioGroup from "../../../shared/components/RadioGroup";
import TextArea from "../../../shared/components/TextArea";
import ExampleLink from "../../../shared/components/ExampleLink";
import ContactInput from "../../../shared/components/ContactInput";
import NeedHelpComponentView from "./views/NeedHelpComponentView";
import TranslationService from "../../../shared/services/TranslationService";
import ValidationService from "../../../shared/services/ValidationService";
import ChoiceService from "../../../shared/services/ChoiceService";
import ErrorViewService from "../../../shared/services/ErrorViewService";
import TrackingService from "../../../shared/services/TrackingService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');
let extend = require('lodash/extend');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
	extend: extend
};

class MechanicalPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			reportingVAT: {value: '',error: ''},
			mileage: {value: '',error: ''},
			where: {value: '',error: ''},
			incidentDescription: {value: '',error: ''},
			drivable: {value: '',error: ''},
			hiredSalvor: {value: '',error: ''},
			vehicleAtWorkshop: {value: '',error: ''},
			workshop: {value: '',error: ''},
			ssn: {value: '',error: ''},
			phoneNumber: {value: '',error: ''},
			email: {value: '',error: ''},
			confirmEmail: {value: '',error: ''},

			translations: []
		};

		this.submitFired = false;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		
		let newState = {};
		_.extend(newState, this.state, this.props.history.location.state);
		this.setState(newState);

		TrackingService.pushPageViewEvent("Skadeanmälan",
			"Skadeanmälan - Uppgifter om skadan",
			"2",
			"Skadeanmälan märkeswebb",
			"Skadeanmälan",
			"Fordon",
			"Maskinskada");
		this.props.sharedInfo.trackingName = "Maskinskada";
		this.props.sharedInfo.trackingExtent = "NotApplicable";
	}

	handleReportingVATChange = e => {this.updateState(e.target.value, 'reportingVAT', this.handleReportingVATValidation);}
	handleMileageChange = e => {this.updateState(e.target.value, 'mileage', this.handleMileageValidation);}
	handleWhereChange = e => {this.updateState(e.target.value, 'where', this.handleWhereValidation);}
	handleIncidentDescriptionChange = e => {this.updateState(e.target.value, 'incidentDescription', this.handleIncidentDescriptionValidation);}
	handleDrivableChange = e => {this.updateState(e.target.value, 'drivable', this.handleDrivableValidation);}
	handleHiredSalvorChange = e => {this.updateState(e.target.value, 'hiredSalvor', this.handleHiredSalvorValidation);}
	handleVehicleAtWorkshopChange = e => {this.updateState(e.target.value, 'vehicleAtWorkshop', this.handleVehicleAtWorkshopValidation);}
	handleWorkshopChange = e => {this.updateState(e.target.value, 'workshop', this.handleWorkshopValidation);}
	handleSsnChange = e => {this.updateState(e.target.value, 'ssn', this.handleSsnValidation);}
	handlePhoneNumberChange = e => {this.updateState(e.target.value, 'phoneNumber', this.handlePhoneNumberValidation);}
	handleEmailChange = e => {this.updateState(e.target.value, 'email', this.handleEmailValidation);}
	handleConfirmEmailChange = e => {this.updateState(e.target.value, 'confirmEmail', this.handleEmailValidation);}

	handleSsnValidation = () => {
		if (this.submitFired) {
			const rules = {
				ssn: 'required|orgSsn|ssn'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/pnrerror'),
				orgSsn: TranslationService.getTranslation('/reportdamageformpage/step2/pnrerror'),
				ssn: TranslationService.getTranslation('/reportdamageformpage/step2/pnrformaterror')
			}

			this.setState({ssn: ValidationService.validate(this.state.ssn, rules, customErrorMessages)});
		}
	}

	handlePhoneNumberValidation = () => {
		if (this.submitFired) {
			const rules = {
				phoneNumber: 'required|phoneNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/phoneerror'),
				phoneNumber: TranslationService.getTranslation('/reportdamageformpage/step2/phoneerror')
			}

			this.setState({phoneNumber: ValidationService.validate(this.state.phoneNumber, rules, customErrorMessages)});
		}
	}

	handleEmailValidation = () => {
		if (this.submitFired) {
			const rules = {
				email: 'required|email',
				confirmEmail: 'required|same:email'
			};

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/emailerror'),
				email: TranslationService.getTranslation('/reportdamageformpage/step2/emailformaterror'),
				same: TranslationService.getTranslation('/reportdamageformpage/step2/confirmemailerror')
			};

			const fields = {
				email: this.state.email, 
				confirmEmail: this.state.confirmEmail
			};

			const validatedFields = ValidationService.compareValidate(fields, rules, customErrorMessages);
			this.setState({email: validatedFields['email'], confirmEmail: validatedFields['confirmEmail']});
		}
	}

	handleReportingVATValidation = () => {this.requiredValidation('reportingVAT', '/reportdamageformpage/yesnoerror');}
	handleMileageValidation = () => {this.requiredValidation('mileage', '/reportdamageformpage/step2/mileageerror');}
	handleWhereValidation = () => {this.requiredValidation('where', '/reportdamageformpage/step2/wheremechanicalerror');}
	handleIncidentDescriptionValidation = () => {this.requiredValidation('incidentDescription', '/reportdamageformpage/step2/incidentdescriptionerror');}
	handleDrivableValidation = () => {this.requiredValidation('drivable', '/reportdamageformpage/yesnoerror');}
	handleHiredSalvorValidation = () => {this.requiredValidation('hiredSalvor', '/reportdamageformpage/yesnoerror');}
	handleVehicleAtWorkshopValidation = () => {this.requiredValidation('vehicleAtWorkshop', '/reportdamageformpage/yesnoerror');}
	handleWorkshopValidation = () => {this.requiredValidation('workshop', '/reportdamageformpage/step2/workshoperror');}

	updateState = (value, property, callback) => {
		let newState = $.extend({}, this.state[property]);
		newState.value = value;

		let object = {};
		object[property] = newState;

		this.setState(object, () => {
			if (callback) {
				callback();
			}
		});
	}

	requiredValidation = (stateField, translationKey) => {
		if (this.submitFired) {
			let rules = {};
			rules[stateField] = 'required';

			let customErrorMessages = {
				required: TranslationService.getTranslation(translationKey)
			};

			let state = {};
			state[stateField] = ValidationService.validate(this.state[stateField], rules, customErrorMessages);
			this.setState(state);
		}
	}

	handleGoBack = () => {
		this.populatePostData();

		var stateWithoutErrors = this.state;
		_.forOwn(stateWithoutErrors, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				stateObject.error = "";
			}
		});

		this.props.history.push({
			pathname: '/val-av-skada',
			state: stateWithoutErrors
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.submitFired = true;
		this.setState(ValidationService.clearErrorsFromState(this.state), () => {
			this.handleReportingVATValidation();
			this.handleMileageValidation();
			this.handleWhereValidation();
			this.handleIncidentDescriptionValidation();
			this.handleDrivableValidation();
			this.handleSsnValidation();
			this.handlePhoneNumberValidation();
			this.handleEmailValidation();

			if (this.state.drivable.value === "0") {
				this.handleHiredSalvorValidation();
				this.handleVehicleAtWorkshopValidation();

				if (this.state.vehicleAtWorkshop.value === "1") {
					this.handleWorkshopValidation();
				}
			}

			if (!ValidationService.stateHasError(this.state)) {
				this.populatePostData();

				this.props.history.push({
					pathname: '/godkann',
					state: this.state
				});
			} else {
				ErrorViewService.scrollToFirstError();
			}
		});
	}

	populatePostData = () => {
		var boolChoices = ChoiceService.getBoolChoices(this.state.translations);
		this.props.postData.damageInformation = [];
		this.props.postData.contactInformation = [];

		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/companyreportsvat'),
			value: ChoiceService.getLabelForValue(boolChoices, this.state.reportingVAT.value)
		});
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/mileage'),
			value: this.state.mileage.value
		});
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/wheremechanical'),
			value: this.state.where.value
		});
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescription'),
			value: this.state.incidentDescription.value
		});
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/drivable'),
			value: ChoiceService.getLabelForValue(boolChoices, this.state.drivable.value)
		});

		if (this.state.drivable.value === "0") {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/hiredsalvor'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.hiredSalvor.value)
			});

			if (this.state.vehicleAtWorkshop.value === "1") {
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/vehicleatworkshop'),
					value: ChoiceService.getLabelForValue(boolChoices, this.state.vehicleAtWorkshop.value)
				});
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/workshop'),
					value: this.state.workshop.value
				});
			}
		}
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/pnr'),
			value: this.state.ssn.value
		});
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/phone'),
			value: this.state.phoneNumber.value
		});
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/email'),
			value: this.state.email.value
		});

		this.props.sharedInfo.email = this.state.email.value;
	}

	render() {
		if (_.isEmpty(this.props.sharedInfo.damageType)) {
			return (
				<Redirect to="/"/>
			);
		} else {
			return (
				<div className="report-damage-form-page-container">
					<h1>{TranslationService.getTranslation('/reportdamageformpage/step1/mechanicaldamage')}</h1>
					<p className="lead">{TranslationService.getTranslation('/reportdamageformpage/step2/preamble')}</p>


					<form onSubmit={(event) => {
						this.handleSubmit(event);
					}}>
						<div className="row">
							<div className="col-lg-8">
								<div className="container p-2 bg-white">
									<div className="row">
										<div className="col-12 z-index-1">
											<ul className="progressbar">
												<li className="success">{TranslationService.getTranslation('/reportdamageformpage/step1/progress_text')}</li>
												<li className="active">{TranslationService.getTranslation('/reportdamageformpage/step2/progress_text')}</li>
												<li>{TranslationService.getTranslation('/reportdamageformpage/step3/progress_text')}</li>
												<li>{TranslationService.getTranslation('/reportdamageformpage/step4/progress_text')}</li>
											</ul>
										</div>
									</div>

									<hr className="mb-2"/>

									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/companyreportsvat')}
														id="companyreport"
														value={this.state.reportingVAT.value}
														error={this.state.reportingVAT.error}
														inline={true}
														items={ChoiceService.getBoolChoices(this.state.translations)}
														onChange={this.handleReportingVATChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6">
											<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/mileage')}
													id="mileage"
													type={'number'}
													value={this.state.mileage.value}
													error={this.state.mileage.error}
													onChange={this.handleMileageChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/wheremechanical')}
													id="where"
													information={TranslationService.getTranslation('/reportdamageformpage/step2/wheremechanicalinformation')}
													value={this.state.where.value}
													error={this.state.where.error}
													onChange={this.handleWhereChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<TextArea   label={TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescription')}
														id="incidentDescription"
														value={this.state.incidentDescription.value}
														error={this.state.incidentDescription.error}
														onChange={this.handleIncidentDescriptionChange}
														removeBottomPadding={true}/>
											<ExampleLink id="damageDescriptionExample" showText="Visa exempel" hiddenText="Dölj exempel">
												<div className="mt-05 p-05 bg-light">
													<p className="text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescriptionexamplemechanical')}</p>
												</div>
											</ExampleLink>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/drivable')}
														id="drivable"
														value={this.state.drivable.value}
														error={this.state.drivable.error}
														inline={true}
														items={ChoiceService.getBoolChoices(this.state.translations)}
														onChange={this.handleDrivableChange}/>
										</div>
									</div>
									{this.state.drivable.value === "0" &&
										<div className="row">
											<div className="col-12">
												<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/hiredsalvor')}
															id="hiredSalvor"
															value={this.state.hiredSalvor.value}
															error={this.state.hiredSalvor.error}
															inline={true}
															items={ChoiceService.getBoolChoices(this.state.translations)}
															onChange={this.handleHiredSalvorChange}/>
											</div>
										</div>
									}
									{this.state.drivable.value === "0" &&
										<div className="row">
											<div className="col-12">
												<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/vehicleatworkshop')}
															id="vehicleAtWorkshop"
															value={this.state.vehicleAtWorkshop.value}
															error={this.state.vehicleAtWorkshop.error}
															inline={true}
															items={ChoiceService.getBoolChoices(this.state.translations)}
															onChange={this.handleVehicleAtWorkshopChange}/>
											</div>
										</div>
									}
									{(this.state.drivable.value === "0" && this.state.vehicleAtWorkshop.value === "1") &&
										<div className="row">
											<div className="col-12">
												<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/workshop')}
														id="workshop"
														value={this.state.workshop.value}
														error={this.state.workshop.error}
														onChange={this.handleWorkshopChange}/>
											</div>
										</div>
									}

									<hr/>

									<ContactInput   translations={this.state.translations}
													ssn={this.state.ssn}
													phoneNumber={this.state.phoneNumber}
													email={this.state.email}
													confirmEmail={this.state.confirmEmail}
													onSsnChange={this.handleSsnChange}
													onPhoneNumberChange={this.handlePhoneNumberChange}
													onEmailChange={this.handleEmailChange}
													onConfirmEmailChange={this.handleConfirmEmailChange}
													onConfirmEmailValidation={this.handleEmailValidation}
									                onPhoneNumberValidation={this.handlePhoneNumberValidation}
									                onSsnValidation={this.handleSsnValidation}
									                onEmailValidation={this.handleEmailValidation}/>

									<hr/>

									<div className="row pt-2">
										<div className="col-sm-6 mb-1 text-left">
											<button type="button" className="btn btn-light btn-cancel" onClick={this.handleGoBack}>
												{TranslationService.getTranslation('/reportdamageformpage/button/back')}
											</button>
										</div>
										<div className="col-sm-6 text-right">
											<button type="submit" className="btn btn-primary">
												{TranslationService.getTranslation('/reportdamageformpage/button/continue')}
											</button>
										</div>
									</div>
								</div>
							</div>
							<NeedHelpComponentView translations={this.state.translations}/>
						</div>
					</form>
				</div>
			);
		}
	}
}

export default MechanicalPage;