﻿var $ = require("jquery");
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import utilities from '../../utilities';
import RadioGroup from "../../../shared/components/RadioGroup";
import NeedHelpComponentView from "./views/NeedHelpComponentView";
import TranslationService from "../../../shared/services/TranslationService";
import TrackingService from "../../../shared/services/TrackingService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');
let extend = require('lodash/extend');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
	extend: extend
};

class BrokenWindowsPage extends Component {

	constructor() {
		super();
		this.CHIP = "CHIP";
		this.CHIP_ON_CHASSI = "CHIP_ON_CHASSI";
		this.DAMAGE = "DAMAGE";
		this.CRASH = "CRASH";
		this.state = {
			brokenWindowsType: {value: '',error: ''},
			brokenWindowsTypes: [],
			translations: []
		};

		this.submitFired = false;
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let newState = {};
		_.extend(newState, this.state, this.props.history.location.state);
		newState.brokenWindowsTypes = this.populateBrokenWindowsTypes(newState.translations);
		this.setState(newState);

		TrackingService.pushPageViewEvent("Skadeanmälan",
			"Skadeanmälan - Uppgifter om skadan",
			"2",
			"Skadeanmälan märkeswebb",
			"Skadeanmälan",
			"Fordon",
			"Stenskott och trasiga rutor");
	}

	componentDidUpdate() {
		utilities.setExternalLinkIconsOnButtons();
	}

	populateBrokenWindowsTypes = (translations) => {
		return [
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/chip'),
				value: this.CHIP
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/chiponchassi'),
				value: this.CHIP_ON_CHASSI
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/damage'),
				value: this.DAMAGE
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/crash'),
				value: this.CRASH
			}
		];
	}

	handleBrokenWindowsTypeChange = e => {
		let newState = $.extend({}, this.state.brokenWindowsType);
		newState.value = e.target.value;

		let state = {};
		state.brokenWindowsType = newState;
		this.setState(state);
	}

	getGlazierLink = () => {
		return window.reportDamageFormAppObject.mapUrl;
	}

	handleGoBack = () => {
		var stateWithoutErrors = this.state;
		_.forOwn(stateWithoutErrors, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				stateObject.error = "";
			}
		});

		this.props.history.push({
			pathname: '/val-av-skada',
			state: stateWithoutErrors
		});
	}

	handleGoToGlazier = () => {
		TrackingService.pushCustomEvent("findServiceCenter");
	}

	handleGoToDamagePage = () => {
		this.props.history.push({
			pathname: '/skada-pa-fordon',
			state: this.state
		});
	}

	render() {
		if (_.isEmpty(this.props.sharedInfo.damageType)) {
			return (
				<Redirect to="/"/>
			);
		} else {
			return (
				<div className="report-damage-form-page-container">
					<h1>{TranslationService.getTranslation('/reportdamageformpage/step1/brokenwindows')}</h1>
					<p className="lead">{TranslationService.getTranslation('/reportdamageformpage/step2/preamble')}</p>

					<div className="row">
						<div className="col-lg-8">
							<div className="container p-2 bg-white">
								<div className="row">
									<div className="col-12 z-index-1">
										<ul className="progressbar">
											<li className="success">{TranslationService.getTranslation('/reportdamageformpage/step1/progress_text')}</li>
											<li className="active">{TranslationService.getTranslation('/reportdamageformpage/step2/progress_text')}</li>
											<li>{TranslationService.getTranslation('/reportdamageformpage/step3/progress_text')}</li>
											<li>{TranslationService.getTranslation('/reportdamageformpage/step4/progress_text')}</li>
										</ul>
									</div>
								</div>

								<hr className="mb-2"/>

								<div className="row">
									<div className="col-12">
										<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/brokenwindowstype')}
													id="whathappened"
													value={this.state.brokenWindowsType.value}
													inline={false}
													items={this.state.brokenWindowsTypes}
													onChange={this.handleBrokenWindowsTypeChange}/>
									</div>
								</div>

								{this.state.brokenWindowsType.value === this.CHIP &&
									<div className="row">
										<div className="col-12">
											<div className="report-damage-form-page-info mb-2">
												<h3>{TranslationService.getTranslation('/reportdamageformpage/step2/glazierheading'+window.siteConfigObject.currentBrand)}</h3>
												<p>{TranslationService.getTranslation('/reportdamageformpage/step2/glaziertext'+window.siteConfigObject.currentBrand)}</p>
												<div className="d-flex justify-content-end">
													<a onClick={this.handleGoToGlazier} href={this.getGlazierLink()} target="_blank" className="btn btn-primary">
														{TranslationService.getTranslation('/reportdamageformpage/step2/glazierbutton'+window.siteConfigObject.currentBrand)}
													</a>
												</div>
											</div>
										</div>
									</div>
								}

								{(this.state.brokenWindowsType.value === this.CHIP_ON_CHASSI || this.state.brokenWindowsType.value === this.DAMAGE || this.state.brokenWindowsType.value === this.CRASH) &&
									<div className="row">
										<div className="col-12">
											<button className="btn btn-primary mb-1" onClick={this.handleGoToDamagePage}>
												{TranslationService.getTranslation('/reportdamageformpage/step2/damagebutton')}
											</button>
										</div>
									</div>
								}

								<hr className="mb-2"/>

								<div className="row">
									<div className="col-sm-6 text-left">
										<button type="button" className="btn btn-light btn-cancel" onClick={this.handleGoBack}>
											{TranslationService.getTranslation('/reportdamageformpage/button/back')}
										</button>
									</div>
								</div>
							</div>
						</div>
						<NeedHelpComponentView translations={this.state.translations}/>
					</div>
				</div>
			);
		}
	}
}

export default BrokenWindowsPage;