﻿// hack to handle multi line buttons with tooltips and their chevron's alignment
$(document).ready(function () {
	var nodes = document.querySelectorAll(".table-block .collapsible-label");
	for (var nidx = 0; nidx < nodes.length; nidx++) {
		var node = nodes[nidx];
		node.innerHTML = node.innerHTML.split(" ").map(function (word) {
			return "<span>" + word + "</span>";
		}).join(" ");
		var spans = node.querySelectorAll("span");
		var offsetLeft = -Number.MAX_VALUE;
		for (var sidx = 0; sidx < spans.length; sidx++) {
			var span = spans[sidx];
			if (span.offsetLeft <= offsetLeft) {
				node.insertBefore(document.createElement("br"), span);
			}
			offsetLeft = span.offsetLeft;
			span.outerHTML = span.innerHTML;
		}
		node.normalize();
	}
});