﻿$('#navbarNav').on("shown.bs.collapse", function () {
	$('body').addClass('noscroll');
});

$('#navbarNav').on("hidden.bs.collapse", function () {
	$('body').removeClass('noscroll');
});

$('body').on("click", ".dropdown-menu", function (e) {
	$(this).parent().is(".show") && e.stopPropagation();
});