﻿var $ = require("jquery");
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Input from "../../../shared/components/Input";
import RadioGroup from "../../../shared/components/RadioGroup";
import TextArea from "../../../shared/components/TextArea";
import Checkbox from "../../../shared/components/Checkbox";
import Select from "../../../shared/components/Select";
import ContactInput from "../../../shared/components/ContactInput";
import ExampleLink from "../../../shared/components/ExampleLink";
import NeedHelpComponentView from "./views/NeedHelpComponentView";
import VehicleWorkingComponentView from "./views/VehicleWorkingComponentView";
import OtherInvolvedComponentView from "./views/OtherInvolvedComponentView";
import TranslationService from "../../../shared/services/TranslationService";
import ValidationService from "../../../shared/services/ValidationService";
import ChoiceService from "../../../shared/services/ChoiceService";
import ErrorViewService from "../../../shared/services/ErrorViewService";
import TrackingService from "../../../shared/services/TrackingService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');
let extend = require('lodash/extend');
let isUndefined = require('lodash/isUndefined');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
	extend: extend,
	isUndefined: isUndefined
};

class DamagePage extends Component {

	constructor(props) {
		super(props);
		this.MOVING_VEHICLE = 'MOVING_VEHICLE';
		this.STILL_VEHICLE = 'STILL_VEHICLE';
		this.PARKED_VEHICLE = 'PARKED_VEHICLE';
		this.state = {
			companyReport: {value: '', error: '', items: []},
			vehicleState: {value: '', error: '', items: []},
			incidentDescription: {value: '', error: ''},
			whereAddress: {value: '', error: ''},
			damageDate: {value: '', error: ''},
			damageTime: {value: '', error: ''},
			whoDriver: {value: '', error: '', items: []},
			driverInformation: {value: '', error: ''},
			numberOfPassangers: {value: '', error: '',
				items: [
					{label: '1',value: '1'},
					{label: '2',value: '2'},
					{label: '3',value: '3'},
					{label: '4',value: '4'},
					{label: '5',value: '5'},
					{label: '6',value: '6'},
					{label: '7',value: '7'}
				]
			},
			otherContact: {value: '', error: ''},
			whoDiscovered: {value: '', error: '', items: []},
			discoveredInformation: {value: '', error: ''},
			injuryinVehicle: {value: '', error: '', items: []},
			otherInvolved: {value: '', error: '', items: []},
			otherInvolvedList: {value: [], error: ''},
			vehicleWorking: {value: '', error: '', items: []},
			workshop: {value: '', error: ''},
			policePresent: {value: '', error: '', items: []},
			damageDescription: {value: '', error: ''},
			injuryinVehicleDescription: {value: '', error: ''},
			hiredSalvor: {value: '', error: '', items: []},
			vehicleatWorkshop: {value: '', error: '', items: []},
			alcoholAnalysis: {value: '', error: '', items: []},
			alcoholAnalysisPositive: {value: '', error: '', items: []},
			policeNotified: {value: '', error: '', items: []},
			policeRefNumber: {value: '', error: ''},
			policeRefNumberNotKnown: {value: '', error: ''},
			ssn: {value: '', error: ''},
			phoneNumber: {value: '', error: ''},
			email: {value: '', error: ''},
			confirmEmail: {value: '', error: ''},
			translations: []
		};

		this.submitFired = false;
	}

	componentDidMount() {
		let newState = {};
		_.extend(newState, this.state, this.props.history.location.state);

		newState.companyReport.items = ChoiceService.getBoolChoices(newState.translations);
		newState.vehicleState.items = this.createVehicleStateItems(newState.translations);
		newState.whoDriver.items = this.createWhoDriverItems(newState.translations);
		newState.injuryinVehicle.items = ChoiceService.getBoolChoices(newState.translations);
		newState.otherInvolved.items = ChoiceService.getBoolChoices(newState.translations);
		newState.vehicleWorking.items = ChoiceService.getBoolChoices(newState.translations);
		newState.policePresent.items = ChoiceService.getBoolChoices(newState.translations);
		newState.hiredSalvor.items = ChoiceService.getBoolChoices(newState.translations);
		newState.vehicleatWorkshop.items = ChoiceService.getBoolChoices(newState.translations);
		newState.alcoholAnalysis.items = ChoiceService.getBoolChoices(newState.translations);
		newState.alcoholAnalysisPositive.items = ChoiceService.getBoolChoices(newState.translations);
		newState.whoDiscovered.items = this.createWhoDiscoveredItems(newState.translations);
		newState.policeNotified.items = ChoiceService.getBoolChoices(newState.translations);

		this.setState(newState);

		TrackingService.pushPageViewEvent("Skadeanmälan",
			"Skadeanmälan - Uppgifter om skadan",
			"2",
			"Skadeanmälan märkeswebb",
			"Skadeanmälan",
			"Fordon",
			"Skada på fordon");
		this.props.sharedInfo.trackingName = "Skada på fordon";
	}

	createVehicleStateItems = (translations) => {
		return [
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/movingvehicle'),
				value: this.MOVING_VEHICLE
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/stillvehicle'),
				value: this.STILL_VEHICLE
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/parkedvehicle'),
				value: this.PARKED_VEHICLE
			}
		];
	}

	createWhoDriverItems = (translations) => {
		return [
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/swedishdriver'),
				value: '1'
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/foreigndriver'),
				value: '2'
			}
		];
	}

	createWhoDiscoveredItems = (translations) => {
		return [
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/myself'),
				value: '1'
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/otherperson'),
				value: '2'
			}
		];
	}

	// Handle change functions
	handleCompanyReportChange = e => {this.updateState(e.target.value, 'companyReport', this.handleCompanyReportValidation);}
	handleVehicleStateChange = e => {this.updateState(e.target.value, 'vehicleState', this.handleVehicleStateValidation);}
	handleIncidentDescriptionChange = e => {this.updateState(e.target.value, 'incidentDescription', this.handleIncidentDescriptionValidation);}
	handleWhereAddressChange = e => {this.updateState(e.target.value, 'whereAddress', this.handleWhereAddressValidation);}
	handleDamageTimeChange = e => {this.updateState(e.target.value, 'damageTime', this.handleDamageTimeValidation);}
	handleWhoDriverChange = e => {this.updateState(e.target.value, 'whoDriver', this.handleWhoDriverValidation);}
	handleDriverInformationChange = e => {this.updateState(e.target.value, 'driverInformation', this.handleDriverInformationValidation);}
	handleNumberOfPassangersChange = e => {this.updateState(e.target.value, 'numberOfPassangers', this.handleNumberOfPassangersValidation);}
	handleOtherContactChange = e => {this.updateState(e.target.value, 'otherContact');}
	handleInjuryinVehicleChange = e => {this.updateState(e.target.value, 'injuryinVehicle', this.handleInjuryinVehicleValidation);}
	handleVehicleWorkingChange = e => {this.updateState(e.target.value, 'vehicleWorking', this.handleVehicleWorkingValidation);}
	handlePolicePresentChange = e => { this.updateState(e.target.value, 'policePresent', this.handlePolicePresentValidation);}
	handleDamageDescriptionChange = e => { this.updateState(e.target.value, 'damageDescription', this.handleDamageDescriptionValidation);}
	handleInjuryinVehicleDescriptionChange = e => { this.updateState(e.target.value, 'injuryinVehicleDescription', this.handleInjuryinVehicleDescriptionValidation);}
	handleHiredSalvorChange = e => { this.updateState(e.target.value, 'hiredSalvor', this.handleHiredSalvorValidation);}
	handleVehicleatWorkshopChange = e => { this.updateState(e.target.value, 'vehicleatWorkshop', this.handleVehicleAtWorkshopValidation);}
	handleAlcoholAnalysisChange = e => { this.updateState(e.target.value, 'alcoholAnalysis', this.handleAlcoholAnalysisValidation);}
	handleAlcoholAnalysisPositiveChange = e => { this.updateState(e.target.value, 'alcoholAnalysisPositive', this.handleAlcoholAnalysisPositiveValidation);}
	handleWorkshopChange = e => { this.updateState(e.target.value, 'workshop', this.handleWorkshopValidation);}
	handleWhoDiscoveredChange = e => {
		this.updateState('', 'discoveredInformation');
		this.updateState(e.target.value, 'whoDiscovered', this.handleWhoDiscoveredValidation);
	}
	handleDiscoveredInformationChange = e => { this.updateState(e.target.value, 'discoveredInformation', this.handlediscoveredInformationValidation);}
	handleOtherInvolvedListChange = value => { this.updateState(value, 'otherInvolvedList');}
	handleOtherInvolvedChange = e => { this.updateState(e.target.value, 'otherInvolved', this.handleOtherInvolvedValidation);}
	handlePoliceNotifiedChange = e => {this.updateState(e.target.value, 'policeNotified', this.handlePoliceNotifiedValidation);}
	handlePoliceRefNumberChange = e => {this.updateState(e.target.value, 'policeRefNumber', this.handlePoliceRefNumberValidation);}
	handlePoliceRefNumberNotKnownChange = e => {
		this.state.policeRefNumber.error = '';
		this.updateState(e.target.checked, 'policeRefNumberNotKnown', this.handlePoliceRefNumberValidation);
	}
	handleSsnChange = e => {this.updateState(e.target.value, 'ssn', this.handleSsnValidation);}
	handlePhoneNumberChange = e => {this.updateState(e.target.value, 'phoneNumber', this.handlePhoneNumberValidation);}
	handleEmailChange = e => {this.updateState(e.target.value, 'email', this.handleEmailValidation);}
	handleConfirmEmailChange = e => {this.updateState(e.target.value, 'confirmEmail', this.handleEmailValidation);}

	// Validation functions
	handleCompanyReportValidation = () => { this.requiredValidation('companyReport', '/reportdamageformpage/yesnoerror'); }
	handleVehicleStateValidation = () => { this.requiredValidation('vehicleState', '/reportdamageformpage/step2/vehiclestateerror'); }
	handleIncidentDescriptionValidation = () => { this.requiredValidation('incidentDescription', '/reportdamageformpage/step2/incidentdescriptionerror'); }
	handleWhereAddressValidation = () => { this.requiredValidation('whereAddress', '/reportdamageformpage/step2/whereerror'); }
	handleDamageTimeValidation = () => { this.requiredValidation('damageTime', '/reportdamageformpage/step2/whentimeerror'); }
	handleWhoDriverValidation = () => { this.requiredValidation('whoDriver', '/reportdamageformpage/step2/whodriverError'); }
	handleDriverInformationValidation = () => { this.requiredValidation('driverInformation', '/reportdamageformpage/step2/drivererror'); }
	handleNumberOfPassangersValidation = () => { this.requiredValidation('numberOfPassangers', '/reportdamageformpage/step2/numberofpassengerserror'); }
	handleInjuryinVehicleValidation = () => { this.requiredValidation('injuryinVehicle', '/reportdamageformpage/step2/injuryinvehicleerror'); }
	handleOtherInvolvedValidation = () => { this.requiredValidation('otherInvolved', '/reportdamageformpage/step2/otherinvolvederror'); }
	handleVehicleWorkingValidation = () => { this.requiredValidation('vehicleWorking', '/reportdamageformpage/step2/vehicleworkingerror'); }
	handlePolicePresentValidation = () => { this.requiredValidation('policePresent', '/reportdamageformpage/step2/policepresenterror'); }
	handleDamageDescriptionValidation = () => { this.requiredValidation('damageDescription', '/reportdamageformpage/step2/damagedescriptionerror'); }
	handleInjuryinVehicleDescriptionValidation = () => { this.requiredValidation('injuryinVehicleDescription', '/reportdamageformpage/step2/injuryinvehicledescriptionerror'); }
	handleHiredSalvorValidation = () => { this.requiredValidation('hiredSalvor', '/reportdamageformpage/yesnoerror'); }
	handleVehicleAtWorkshopValidation = () => { this.requiredValidation('vehicleatWorkshop', '/reportdamageformpage/yesnoerror'); }
	handleAlcoholAnalysisValidation = () => { this.requiredValidation('alcoholAnalysis', '/reportdamageformpage/yesnoerror'); }
	handleAlcoholAnalysisPositiveValidation = () => { this.requiredValidation('alcoholAnalysisPositive', '/reportdamageformpage/yesnoerror'); }
	handleWorkshopValidation = () => { this.requiredValidation('workshop', '/reportdamageformpage/step2/workshoperror'); }
	handleWhoDiscoveredValidation = () => { this.requiredValidation('whoDiscovered', '/reportdamageformpage/step2/whodiscoverederror'); }
	handlediscoveredInformationValidation = () => { this.requiredValidation('discoveredInformation', '/reportdamageformpage/step2/whodiscoveredotherpersonerror'); }
	handlePoliceNotifiedValidation = () => { this.requiredValidation('policeNotified', '/reportdamageformpage/yesnoerror'); }
	
	handlePoliceRefNumberValidation = () => {
		if (!this.state.policeRefNumberNotKnown.value) {
			this.requiredValidation('policeRefNumber', '/reportdamageformpage/step2/policerefnumbererror');
		}
	}

	handleSsnValidation = () => {
		if (this.submitFired) {
			const rules = {
				ssn: 'required|orgSsn|ssn'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/pnrerror'),
				orgSsn: TranslationService.getTranslation('/reportdamageformpage/step2/pnrerror'),
				ssn: TranslationService.getTranslation('/reportdamageformpage/step2/pnrformaterror')
			}

			this.setState({ssn: ValidationService.validate(this.state.ssn, rules, customErrorMessages)});
		}
	}

	handlePhoneNumberValidation = () => {
		if (this.submitFired) {
			const rules = {
				phoneNumber: 'required|phoneNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/phoneerror'),
				phoneNumber: TranslationService.getTranslation('/reportdamageformpage/step2/phoneerror')
			}

			this.setState({phoneNumber: ValidationService.validate(this.state.phoneNumber, rules, customErrorMessages)});
		}
	}

	handleEmailValidation = () => {
		if (this.submitFired) {
			const rules = {
				email: 'required|email',
				confirmEmail: 'required|same:email'
			};

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/emailerror'),
				email: TranslationService.getTranslation('/reportdamageformpage/step2/emailformaterror'),
				same: TranslationService.getTranslation('/reportdamageformpage/step2/confirmemailerror')
			};

			const fields = {
				email: this.state.email, 
				confirmEmail: this.state.confirmEmail
			};

			let validatedFields = ValidationService.compareValidate(fields, rules, customErrorMessages);
			this.setState({email: validatedFields['email'], confirmEmail: validatedFields['confirmEmail']});
		}
	}

	updateState = (value, property, callback) => {
		let newState = $.extend({}, this.state[property]);
		newState.value = value;

		let object = {};
		object[property] = newState;

		this.setState(object, () => {
			if (callback) {
				callback();
			}
		});
	}

	requiredValidation = (stateField, translationKey) => {
		if (this.submitFired) {
			let rules = {};
			rules[stateField] = 'required';

			let customErrorMessages = {
				required: TranslationService.getTranslation(translationKey)
			};

			let state = {};
			state[stateField] = ValidationService.validate(this.state[stateField], rules, customErrorMessages);
			this.setState(state);
		}
	}

	handleGoBack = () => {
		this.props.history.push({
			pathname: '/val-av-skada',
			state: this.state
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.submitFired = true;
		this.setState(ValidationService.clearErrorsFromState(this.state), () => {
			this.handleCompanyReportValidation();
			this.handleVehicleStateValidation();
			this.handleIncidentDescriptionValidation();
			this.handleWhereAddressValidation();
			this.handleDamageTimeValidation();
			this.handleDamageDescriptionValidation();
			this.handleOtherInvolvedValidation();
			this.validateWhoDriver();
			this.validateVehicleWorkingComponent();
			this.handleSsnValidation();
			this.handlePhoneNumberValidation();
			this.handleEmailValidation();

			if (this.state.vehicleState.value === this.MOVING_VEHICLE) {
				this.validateMovingVehicleForm();
			} else if (this.state.vehicleState.value === this.STILL_VEHICLE) {
				this.validateStillVehicleForm();
			} else if (this.state.vehicleState.value === this.PARKED_VEHICLE) {
				this.validateParkedVehicleForm();
			}
		
			if (!ValidationService.stateHasError(this.state)) {
				this.populatePostData();

				this.props.history.push({
					pathname: '/godkann',
					state: this.state
				});
			} else {
				ErrorViewService.scrollToFirstError();
			}
		});
	}

	validateMovingVehicleForm = () => {
		this.handleNumberOfPassangersValidation();
		this.handleInjuryinVehicleValidation();
		this.validateInjuryinVehicle();
		this.validatePolicePresent();
	}

	validateStillVehicleForm = () => {
		this.handleNumberOfPassangersValidation();
		this.handleInjuryinVehicleValidation();
		this.validateInjuryinVehicle();
		this.validatePolicePresent();
	}

	validateParkedVehicleForm = () => {
		this.validateWhoDiscovered();
		this.validatePoliceNotified();
	}

	validatePoliceNotified = () => {
		this.handlePoliceNotifiedValidation();

		if (this.state.policeNotified.value === '1') {
			this.handlePoliceRefNumberValidation();
		}
	}

	validateWhoDriver = () => {
		this.handleWhoDriverValidation();

		if (!_.isEmpty(this.state.whoDriver.value)) {
			this.handleDriverInformationValidation();
		}
	}

	validateWhoDiscovered = () => {
		this.handleWhoDiscoveredValidation();

		if (!_.isEmpty(this.state.whoDiscovered.value) && this.state.whoDiscovered.value === '2') {
			this.handlediscoveredInformationValidation();
		}
	}

	validateInjuryinVehicle = () => {
		if (!_.isEmpty(this.state.injuryinVehicle.value) && !!+this.state.injuryinVehicle.value) {
			this.handleInjuryinVehicleDescriptionValidation();
		}
	}

	validatePolicePresent = () => {
		this.handlePolicePresentValidation();

		if (!_.isEmpty(this.state.policePresent.value) && !!+this.state.policePresent.value) {
			this.handleAlcoholAnalysisValidation();

			if (!_.isEmpty(this.state.alcoholAnalysis.value) && !!+this.state.alcoholAnalysis.value) {
				this.handleAlcoholAnalysisPositiveValidation();
			}

			if (this.state.policePresent.value === '1') {
				this.handlePoliceRefNumberValidation();
			}
		}
	}

	validateVehicleWorkingComponent = () => {
		this.handleVehicleWorkingValidation();

		if (!_.isEmpty(this.state.vehicleWorking.value) && !+this.state.vehicleWorking.value) {
			this.handleHiredSalvorValidation();
			this.handleVehicleAtWorkshopValidation();

			if (!_.isEmpty(this.state.vehicleatWorkshop.value) && !!+this.state.vehicleatWorkshop.value) {
				this.handleWorkshopValidation();
			}
		}
	}

	populatePostData = () => {
		var boolChoices = ChoiceService.getBoolChoices(this.state.translations);
		this.props.postData.damageInformation = [];
		this.props.postData.contactInformation = [];

		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/companyreportsvat'),
			value: ChoiceService.getLabelForValue(boolChoices, this.state.companyReport.value)
		});

		var vehicleState = ChoiceService.getLabelForValue(this.createVehicleStateItems(this.state.translations), this.state.vehicleState.value);
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/vehiclestate'),
			value: vehicleState
		});
		this.props.sharedInfo.trackingExtent = vehicleState;

		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescription'),
			value: this.state.incidentDescription.value
		});
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/where'),
			value: this.state.whereAddress.value
		});

		if (this.state.vehicleState.value === this.MOVING_VEHICLE || this.state.vehicleState.value === this.STILL_VEHICLE) {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/whentime'),
				value: this.state.damageTime.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/whodriver'),
				value: ChoiceService.getLabelForValue(this.createWhoDriverItems(this.state.translations), this.state.whoDriver.value)
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/driver'),
				value: this.state.driverInformation.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/numberofpassengers'),
				value: this.state.numberOfPassangers.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/othercontact'),
				value: this.state.otherContact.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/injuryinvehicle'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.injuryinVehicle.value)
			});
			if (this.state.injuryinVehicle.value === '1') {
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/injuryinvehicledescription'),
					value: this.state.injuryinVehicleDescription.value
				});
			}

			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/otherinvolotved'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.otherInvolved.value)
			});
			this.populateOtherInvolvedList();

			// Police information
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/policepresent'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.policePresent.value)
			});
			if (this.state.policePresent.value === '1') {
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/alcoholAnalysis'),
					value: ChoiceService.getLabelForValue(boolChoices, this.state.alcoholAnalysis.value)
				});
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumber'),
					value: !this.state.policeRefNumberNotKnown.value ? this.state.policeRefNumber.value : TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumbernotknown')
				});
			}
			if (this.state.alcoholAnalysis.value === '1') {
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/alcoholAnalysisPositive'),
					value: ChoiceService.getLabelForValue(boolChoices, this.state.alcoholAnalysisPositive.value)
				});
			}
		} else if (this.state.vehicleState.value === this.PARKED_VEHICLE) {

			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/whentimeparking'),
				value: this.state.damageTime.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/whodriverparking'),
				value: ChoiceService.getLabelForValue(this.createWhoDriverItems(this.state.translations), this.state.whoDriver.value)
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/driver'),
				value: this.state.driverInformation.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/whodiscovered'),
				value: this.state.whoDiscovered.value === '1' ? ChoiceService.getLabelForValue(this.createWhoDiscoveredItems(this.state.translations), this.state.whoDiscovered.value) : this.state.discoveredInformation.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/othercontactparking'),
				value: this.state.otherContact.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/otherinvolotvedparked'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.otherInvolved.value)
			});
			this.populateOtherInvolvedList();

			// Police Notified 
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/policenotified'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.policeNotified.value)
			});

			if (this.state.policeNotified.value === '1') {
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumber'),
					value: !this.state.policeRefNumberNotKnown.value ? this.state.policeRefNumber.value : TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumbernotknown')
				});
			}
		}

		// Vehicle at workshop 
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/vehicleworking'),
			value: ChoiceService.getLabelForValue(boolChoices, this.state.vehicleWorking.value)
		});
		if (this.state.vehicleWorking.value === '0') {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/hiredsalvor'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.hiredSalvor.value)
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/vehicleatworkshop'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.vehicleatWorkshop.value)
			});
			if (this.state.vehicleatWorkshop.value === '1') {
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/workshop'),
					value: this.state.workshop.value
				});
			}
		}

		// Damage description
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/damagedescription'),
			value: this.state.damageDescription.value
		});

		// Contact information
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/pnr'),
			value: this.state.ssn.value
		});
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/phone'),
			value: this.state.phoneNumber.value
		});
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/email'),
			value: this.state.email.value
		});

		this.props.sharedInfo.email = this.state.email.value;
	}

	populateOtherInvolvedList = () => {
		this.state.otherInvolvedList.value.map((item, index) => {
			this.props.postData.damageInformation.push({
				label: 'Motpart ' + (index+1) + ' - ' + TranslationService.getTranslation('/startpage/barker/regnr'),
				value: item.plateNumber.value
			});

			this.props.postData.damageInformation.push({
				label: 'Motpart ' + (index+1) + ' - ' + TranslationService.getTranslation('/reportdamageformpage/step2/injuryinvehicledescription'),
				value: item.contactInformation.value
			});
		});
	}

	getPolicePresentInformationText = () => {
		if (this.state.policePresent.value === '0' || this.state.policePresent.value === '') {
			return TranslationService.getTranslation('/reportdamageformpage/step2/policepresentinfo');
		} else {
			return '';
		}
	}

	render() {
		if (_.isEmpty(this.props.sharedInfo.damageType)) {
			return (
				<Redirect to="/"/>
			);
		} else {
			return (
				<div className="report-damage-form-page-container">
					<h1>{TranslationService.getTranslation('/reportdamageformpage/step1/vehicledamage')}</h1>
					<p className="lead">{TranslationService.getTranslation('/reportdamageformpage/step2/preamble')}</p>

					<form onSubmit={(event) => {
						this.handleSubmit(event);
					}}>
						<div className="row">
							<div className="col-lg-8">
								<div className="container p-2 bg-white">
									<div className="row">
										<div className="col-12 z-index-1">
											<ul className="progressbar">
												<li className="success">{TranslationService.getTranslation('/reportdamageformpage/step1/progress_text')}</li>
												<li className="active">{TranslationService.getTranslation('/reportdamageformpage/step2/progress_text')}</li>
												<li>{TranslationService.getTranslation('/reportdamageformpage/step3/progress_text')}</li>
												<li>{TranslationService.getTranslation('/reportdamageformpage/step4/progress_text')}</li>
											</ul>
										</div>
									</div>
									
									<hr className="mb-2"/>
									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/companyreportsvat')}
														id="companyReport"
														value={this.state.companyReport.value}
														error={this.state.companyReport.error}
														items={this.state.companyReport.items}
														inline={true}
														onChange={this.handleCompanyReportChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/vehiclestate')}
														id="vehicleState"
														value={this.state.vehicleState.value}
														error={this.state.vehicleState.error}
														items={this.state.vehicleState.items}
														onChange={this.handleVehicleStateChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<TextArea label={TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescription')}
														id="incidentDescription"
														value={this.state.incidentDescription.value}
														error={this.state.incidentDescription.error}
														onChange={this.handleIncidentDescriptionChange}
														onValidate={this.handleIncidentDescriptionValidation}
														removeBottomPadding={true}/>
											<ExampleLink id="incidentDescriptionExample" showText="Visa exempel" hiddenText="Dölj exempel">
												<div className="mt-05 p-05 bg-light">
													<p className="mb-1 text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescriptionexample1')}</p>
													<p className="mb-1 text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescriptionexample2')}</p>
													<p className="mb-1 text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/incidentdescriptionexample3')}</p>
												</div>
											</ExampleLink>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/where')}
													id="whereAddress"
													value={this.state.whereAddress.value}
													error={this.state.whereAddress.error}
													onChange={this.handleWhereAddressChange}
													information={TranslationService.getTranslation('/reportdamageformpage/step2/wheredescription')}
													onValidate={this.handleWhereAddressValidation}/>
										</div>
									</div>
									
									{(this.state.vehicleState.value === this.MOVING_VEHICLE || this.state.vehicleState.value === this.STILL_VEHICLE) &&
										<div>
												<div className="row">
													<div className="col-12 col-md-6">
														<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/whentime')}
																id="damageTime"
																value={this.state.damageTime.value}
																error={this.state.damageTime.error}
																onChange={this.handleDamageTimeChange}
																onValidate={this.handleDamageTimeValidation}/>
													</div>
												</div>
												<div className="row">
													<div className="col-12 col-md-6">
														<Select label={TranslationService.getTranslation('/reportdamageformpage/step2/whodriver')}
																id="whoDriver"
																value={this.state.whoDriver.value}
																error={this.state.whoDriver.error}
																items={this.state.whoDriver.items}
																onChange={this.handleWhoDriverChange}/>
													</div>
												</div>

												{!_.isEmpty(this.state.whoDriver.value) && 
													<div className="row">
														<div className="col-12">
															<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/driver')}
																	id="driver"
																	value={this.state.driverInformation.value}
																	error={this.state.driverInformation.error}
																	onChange={this.handleDriverInformationChange}
																	onValidate={this.handleDriverInformationValidation}/>
														</div>
													</div>
												}
															
												<div className="row">
													<div className="col-12 col-md-6">
														<Select label={TranslationService.getTranslation('/reportdamageformpage/step2/numberofpassengers')}
																id="numberOfPersons"
																value={this.state.numberOfPassangers.value}
																error={this.state.numberOfPassangers.error}
																items={this.state.numberOfPassangers.items}
																onChange={this.handleNumberOfPassangersChange}/>
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<TextArea label={TranslationService.getTranslation('/reportdamageformpage/step2/othercontact')}
																id="otherContact"
																value={this.state.otherContact.value}
																error={this.state.otherContact.error}
																onChange={this.handleOtherContactChange}
																removeBottomPadding={true}/>
													<ExampleLink id="otherContactExample" showText="Visa exempel" hiddenText="Dölj exempel">
														<div className="mt-05 p-05 bg-light">
															<p className="mb-0 text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/othercontactexample')}</p>
														</div>
													</ExampleLink>
												</div>
											</div>
									
											<div className="row">
												<div className="col-12">
													<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/injuryinvehicle')}
																id="injuryinVehicle"
																value={this.state.injuryinVehicle.value}
																error={this.state.injuryinVehicle.error}
																items={this.state.injuryinVehicle.items}
																inline={true}
																onChange={this.handleInjuryinVehicleChange}/>
												</div>
											</div>

											{!_.isEmpty(this.state.injuryinVehicle.value) && !!+this.state.injuryinVehicle.value &&
												<div className="row">
													<div className="col-12">
														<TextArea label={TranslationService.getTranslation('/reportdamageformpage/step2/injuryinvehicledescription')}
																	id="injuryinVehicleDescription"
																	value={this.state.injuryinVehicleDescription.value}
																	error={this.state.injuryinVehicleDescription.error}
																	onChange={this.handleInjuryinVehicleDescriptionChange}
																	removeBottomPadding={true}/>
														<ExampleLink id="injuryinVehicleDescriptionExample" showText="Visa exempel" hiddenText="Dölj exempel">
															<div className="mt-05 p-05 bg-light">
																<p className="text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/injuryinvehicledescriptionexample')}</p>
															</div>
														</ExampleLink>
													</div>
												</div>
											}

											<div className="row">
												<div className="col-12">
													<OtherInvolvedComponentView translations={this.state.translations}
																				radioButtonTranslationKey={'/reportdamageformpage/step2/otherinvolotved'}
																				radioButtonInformationTranslationKey={'/reportdamageformpage/step2/otherinvolvedinfo'}
																				otherInvolvedObject={this.state.otherInvolved}
																				handleOtherInvolvedChange={this.handleOtherInvolvedChange}
																				otherInvolvedList={this.state.otherInvolvedList.value}
																				handleOtherInvolvedListChange={this.handleOtherInvolvedListChange}
													/>
												</div>
											</div>
										</div>
									}

									{this.state.vehicleState.value === this.PARKED_VEHICLE &&
										<div>
											<div className="row">
												<div className="col-12">
													<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/whentimeparking')}
													       id="damageTime"
													       value={this.state.damageTime.value}
													       error={this.state.damageTime.error}
													       onChange={this.handleDamageTimeChange}
													       onValidate={this.handleDamageTimeValidation}/>
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-6">
													<Select label={TranslationService.getTranslation('/reportdamageformpage/step2/whodriverparking')}
													        id="whoDriver"
													        value={this.state.whoDriver.value}
													        error={this.state.whoDriver.error}
													        items={this.state.whoDriver.items}
													        onChange={this.handleWhoDriverChange}/>
												</div>
												<div className="col-12 col-md-6">
													<Select label={TranslationService.getTranslation('/reportdamageformpage/step2/whodiscovered')}
													        id="whoDiscovered"
													        value={this.state.whoDiscovered.value}
													        error={this.state.whoDiscovered.error}
													        items={this.state.whoDiscovered.items}
													        onChange={this.handleWhoDiscoveredChange}/>
												</div>
											</div>

											<div className="row">
												{!_.isEmpty(this.state.whoDriver.value) && 
													<div className="col-12 col-md-6">
														<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/driver')}
																id="driver"
																value={this.state.driverInformation.value}
																error={this.state.driverInformation.error}
																onChange={this.handleDriverInformationChange}
																onValidate={this.handleDriverInformationValidation}/>
													 </div>
												}
												{(!_.isEmpty(this.state.whoDiscovered.value) && this.state.whoDiscovered.value === '2') && 
													<div className="col-12 col-md-6">
														<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/whodiscoveredotherperson')}
																id="discoveredInformation"
																value={this.state.discoveredInformation.value}
																error={this.state.discoveredInformation.error}
																onChange={this.handleDiscoveredInformationChange}
																onValidate={this.handlediscoveredInformationValidation}/>
													</div>
												}
											</div>

											<div className="row">
												<div className="col-12">
													<OtherInvolvedComponentView translations={this.state.translations}
																				radioButtonTranslationKey={'/reportdamageformpage/step2/otherinvolotvedparked'}
																				radioButtonInformationTranslationKey={'/reportdamageformpage/step2/otherinvolvedinfoparked'}
																				otherInvolvedObject={this.state.otherInvolved}
																				handleOtherInvolvedChange={this.handleOtherInvolvedChange}
																				otherInvolvedList={this.state.otherInvolvedList.value}
																				handleOtherInvolvedListChange={this.handleOtherInvolvedListChange}
													/>
												</div>
											</div>

											<div className="row">
												<div className="col-12">
													<TextArea label={TranslationService.getTranslation('/reportdamageformpage/step2/othercontactparking')}
															id="otherContact"
															value={this.state.otherContact.value}
															error={this.state.otherContact.error}
															onChange={this.handleOtherContactChange}
															removeBottomPadding={true}/>
													<ExampleLink id="otherContactExample" showText="Visa exempel" hiddenText="Dölj exempel">
														<div className="mt-05 p-05 bg-light">
															<p className="mb-0 text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/othercontactexampleparking')}</p>
														</div>
													</ExampleLink>
												</div>
											</div>
										</div>
									}

									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/vehicleworking')}
														id="vehicleWorking"
														value={this.state.vehicleWorking.value}
														error={this.state.vehicleWorking.error}
														items={this.state.vehicleWorking.items}
														inline={true}
														information={TranslationService.getTranslation('/reportdamageformpage/step2/vehicleworkinginfo')}
														onChange={this.handleVehicleWorkingChange}/>
										</div>
									</div>

									{!_.isEmpty(this.state.vehicleWorking.value) && !+this.state.vehicleWorking.value &&
										<VehicleWorkingComponentView translations={this.state.translations}
																	hiredSalvorObject={this.state.hiredSalvor}
																	vehicleatWorkshopObject={this.state.vehicleatWorkshop}
																	workshopObject={this.state.workshop}
																	handleHiredSalvorChange={this.handleHiredSalvorChange}
																	handleVehicleatWorkshopChange={this.handleVehicleatWorkshopChange}
																	handleWorkshopChange={this.handleWorkshopChange}
																	handleWorkshopValidation={this.handleWorkshopValidation}/>
									}

									{this.state.vehicleState.value === this.PARKED_VEHICLE && 
										<div>
											<div className="row">
												<div className="col-12">
													<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/policenotified')}
																id="policeNotified"
																value={this.state.policeNotified.value}
																error={this.state.policeNotified.error}
																items={this.state.policeNotified.items}
																inline={true}
																onChange={this.handlePoliceNotifiedChange}/>
												</div>
											</div>
											{this.state.policeNotified.value === "1" &&
												<div className="row">
													<div className="col-sm-9">
														<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumber')}
																id="policeRefNumber"
																formGroupClass={'mb-1-xs-down'}
																value={this.state.policeRefNumber.value}
																error={this.state.policeRefNumber.error}
																onChange={this.handlePoliceRefNumberChange}
																onValidate={this.handlePoliceRefNumberValidation}/>
													</div>
													<div className="col-sm-3 ref-number-not-known">
														<Checkbox text={TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumbernotknown')}
																	id="policeRefNumberNotKnown"
																	value={this.state.policeRefNumberNotKnown.value}
																	error={this.state.policeRefNumberNotKnown.error}
																	onChange={this.handlePoliceRefNumberNotKnownChange}/>
													</div>
												</div>
											}
										</div>
									}

									{(this.state.vehicleState.value === this.MOVING_VEHICLE || this.state.vehicleState.value === this.STILL_VEHICLE) &&
										<div>
											<div className="row">
												<div className="col-12">
													<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/policepresent')}
																id="policePresent"
																value={this.state.policePresent.value}
																error={this.state.policePresent.error}
																items={this.state.policePresent.items}
																inline={true}
																information={this.getPolicePresentInformationText()}
																onChange={this.handlePolicePresentChange}/>
												</div>
											</div>

											{!_.isEmpty(this.state.policePresent.value) && !!+this.state.policePresent.value &&
												<div>
													<div className="row">
														<div className="col-12">
															<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/alcoholAnalysis')}
																		id="alcoholAnalysis"
																		value={this.state.alcoholAnalysis.value}
																		error={this.state.alcoholAnalysis.error}
																		items={this.state.alcoholAnalysis.items}
																		inline={true}
																		onChange={this.handleAlcoholAnalysisChange}/>
														</div>
													</div>

													{!_.isEmpty(this.state.alcoholAnalysis.value) && !!+this.state.alcoholAnalysis.value &&
														<div className="row">
															<div className="col-12">
																<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/alcoholAnalysisPositive')}
																			id="alcoholAnalysisPositive"
																			value={this.state.alcoholAnalysisPositive.value}
																			error={this.state.alcoholAnalysisPositive.error}
																			items={this.state.alcoholAnalysisPositive.items}
																			inline={true}
																			onChange={this.handleAlcoholAnalysisPositiveChange}/>
															</div>
														</div>
													}
												</div>
											}

											{this.state.policePresent.value === "1" &&
												<div className="row">
													<div className="col-sm-9">
														<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumber')}
																id="policeRefNumber"
																formGroupClass={'mb-1-xs-down'}
																value={this.state.policeRefNumber.value}
																error={this.state.policeRefNumber.error}
																onChange={this.handlePoliceRefNumberChange}
																onValidate={this.handlePoliceRefNumberValidation}/>
													</div>
													<div className="col-sm-3 ref-number-not-known">
														<Checkbox text={TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumbernotknown')}
																	id="policeRefNumberNotKnown"
																	value={this.state.policeRefNumberNotKnown.value}
																	error={this.state.policeRefNumberNotKnown.error}
																	onChange={this.handlePoliceRefNumberNotKnownChange}/>
													</div>
												</div>
											}
										</div>
									}

									<div className="row">
										<div className="col-12">
											<TextArea label={TranslationService.getTranslation('/reportdamageformpage/step2/damagedescription')}
														id="damageDescription"
														value={this.state.damageDescription.value}
														error={this.state.damageDescription.error}
														onChange={this.handleDamageDescriptionChange}
														onValidate={this.handleDamageDescriptionValidation}
														removeBottomPadding={true}/>
											<ExampleLink id="damageDescriptionExample" showText="Visa exempel" hiddenText="Dölj exempel">
												<div className="mt-05 p-05 bg-light">
													<p className="mb-0 text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/damagedescriptionexample')}</p>
												</div>
											</ExampleLink>
										</div>
									</div>

									<hr/>

									<ContactInput translations={this.state.translations}
													ssn={this.state.ssn}
													phoneNumber={this.state.phoneNumber}
													email={this.state.email}
													confirmEmail={this.state.confirmEmail}
													onSsnChange={this.handleSsnChange}
													onPhoneNumberChange={this.handlePhoneNumberChange}
													onEmailChange={this.handleEmailChange}
													onConfirmEmailChange={this.handleConfirmEmailChange}
													onConfirmEmailValidation={this.handleEmailValidation}
													onPhoneNumberValidation={this.handlePhoneNumberValidation}
													onSsnValidation={this.handleSsnValidation}
													onEmailValidation={this.handleEmailValidation}/>

									<hr/>

									<div className="row delimiter-xs pt-1">
										<div className="col-sm-6 mb-1 text-left">
											<button type="button" className="btn btn-light btn-cancel" onClick={this.handleGoBack}>
												{TranslationService.getTranslation('/reportdamageformpage/button/back')}
											</button>
										</div>
										<div className="col-sm-6 text-right">
											<button type="submit" className="btn btn-primary">
												{TranslationService.getTranslation('/reportdamageformpage/button/continue')}
											</button>
										</div>
									</div>
								</div>
							</div>
							<NeedHelpComponentView translations={this.state.translations}/>
						</div>
					</form>
				</div>
			);
		}
	}
}

export default DamagePage;