﻿import React, { Component } from 'react';

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined
};

class Input extends Component {

	constructor() {
		super();
		this.dirty = false;
	}

	onChange = (e) => {
		this.dirty = true;
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	};

	onBlur = (e) => {
		if (this.props.onValidate) {
			this.props.onValidate(e);
		}
	};

	onKeyPress = (e) => {
		if (this.props.onKeyPress && e.key === 'Enter') {
			this.props.onKeyPress(e);
		}
	};

	_shouldAddValidClass = () => {
		const {value, error} = this.props;
		return (this.dirty && _.isUndefined(value)) || (!_.isEmpty(value) && (_.isUndefined(error) || _.isEmpty(error)));
	};

	render() {
		const {label, placeholder, value, id, addon, error, formGroupClass, isDisabled, plateInput, link, information, maxLength, type, ariaLabel} = this.props;

		return (
			<div className={'form-group ' + (!_.isUndefined(formGroupClass) ? formGroupClass : '') +
				(!_.isUndefined(error) && !_.isEmpty(error) ? ' has-danger' : '')}>
				
				{!_.isEmpty(label) &&
					<label htmlFor={id}>{label}</label>
				}
				<div className="input-group">

					{plateInput &&
						<div className="input-group-prepend">
							<span className="input-group-text input-group-plate"></span>
						</div>
					}

					{maxLength &&
						<input type={!_.isEmpty(type) ? type : 'text'}
								placeholder={placeholder}
								aria-label={ariaLabel}
								className="form-control"
								value={value}
								id={id}
								maxLength={maxLength}
								disabled={isDisabled}
								onChange={this.onChange}
								onBlur={this.onBlur}
								onKeyPress={this.onKeyPress}/>
					}

					{!maxLength &&
						<input type={!_.isEmpty(type) ? type : 'text'}
								placeholder={placeholder}
								aria-label={ariaLabel}
								className="form-control"
								value={value}
								id={id}
								disabled={isDisabled}
								onChange={this.onChange}
								onBlur={this.onBlur}
								onKeyPress={this.onKeyPress}/>
					}

					{!_.isUndefined(addon) && !_.isEmpty(addon) &&
						<div className="input-group-append">
							<div className="input-group-text">
								{addon}
							</div>
						</div>
					}
				</div>
				<span className="field-validation-error">
					<span>
						{error}
					</span>
				</span>
				{!_.isUndefined(link) && !_.isEmpty(link.url) && !_.isEmpty(link.text) &&
					<a target="_blank" 
						href={link.url} 
						className="text-muted text-xs d-block mt-03">
						{link.text}
					</a>
				}
				<span className="form-check-info text-xs">
					{information}
				</span>
			</div>
		);
	}
}

export default Input;