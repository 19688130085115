﻿import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom'

import SelectDamageTypePage from "./components/SelectDamageTypePage";
import DamagePage from "./components/DamagePage";
import TheftPage from "./components/TheftPage";
import MechanicalPage from "./components/MechanicalPage";
import BrokenWindowsPage from "./components/BrokenWindowsPage";
import SummaryPage from "./components/SummaryPage";
import ThankYouPage from "./components/ThankYouPage";

class App extends Component {
	
	constructor() {
		super();
		this.state = {
			sharedInfo: {
				damageType: '',
				trackingName: '',
				trackingExtent: ''
			},
			postData: {
				selectDamageType: [],
				damageInformation: [],
				contactInformation: []
			}
		};
	}

	render() {
		return (
			<HashRouter>
				<Switch>
					<Route path="/val-av-skada" render={(props) => <SelectDamageTypePage {...props} sharedInfo={this.state.sharedInfo} postData={this.state.postData} />} />
					<Route path="/skada-pa-fordon" render={(props) => <DamagePage {...props} sharedInfo={this.state.sharedInfo} postData={this.state.postData} />} />
					<Route path="/inbrott-och-stold" render={(props) => <TheftPage {...props} sharedInfo={this.state.sharedInfo} postData={this.state.postData} />} />
					<Route path="/maskinskada" render={(props) => <MechanicalPage {...props} sharedInfo={this.state.sharedInfo} postData={this.state.postData} />} />
					<Route path="/stenskott-och-trasiga-rutor" render={(props) => <BrokenWindowsPage {...props} sharedInfo={this.state.sharedInfo} postData={this.state.postData} />} />
					<Route path="/godkann" render={(props) => <SummaryPage {...props} sharedInfo={this.state.sharedInfo} postData={this.state.postData} />} />
					<Route path="/kvitto" render={(props) => <ThankYouPage {...props} sharedInfo={this.state.sharedInfo} />} />
					<Redirect from="/" to="/val-av-skada" />
				</Switch>
			</HashRouter>
		);
	}
}

if (document.getElementById('report-damage-form-page-app')) {
	ReactDOM.render(<App/>, document.getElementById('report-damage-form-page-app'));
}