﻿class ErrorViewService {

	scrollToFirstError = () => {
		setTimeout(() => {
			$('html, body').animate({
				scrollTop: ($($('.has-danger')[0]).offset().top - 50)
			}, 300);
		}, 200);
	};
}

export default new ErrorViewService();