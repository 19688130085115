﻿import React, { Component } from 'react';

import Input from "../../components/Input.js";
import TranslationService from "../../services/TranslationService";
import ValidationService from "../../services/ValidationService";
import CarriageWarrantyResource from "../../resources/CarriageWarrantyResource";

// Lodash functions
let find = require('lodash/find');
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');

const _ = {
	find: find,
	forOwn: forOwn,
	isEmpty: isEmpty
};

class CarriageWarrantyBlockComponent extends Component {

	constructor() {
		super();
		this.TOYOTA_WARRANTY_PRODUCTTYPE = "TVG";
		this.LEXUS_WARRANTY_PRODUCTTYPE = "LVG";
		this.SUZUKI_WARRANTY_PRODUCTTYPE = "SZV";
		this.viewStateConstants = {
			SUCCESS: 'SUCCESS',
			ERROR: 'ERROR'
		}
		this.state = {
			viewState: '',
			plateNumber: {
				value: '',
				error: ''
			},
			searchResult: {
			},
			translations: [],
			respondedWithError: {
				value: false,
				errorCode: '',
			}
		}
		this.validatePlateNumber = false;
	}

	componentDidUpdate() {
		BM.utilities.setExternalLinkIconsOnButtons();
	}

	handlePlateNumberChange = e => {
		if (e.target.value.length === 6) {
			this.validatePlateNumber = true;
		}

		let newState = $.extend({}, this.state.plateNumber);
		newState.value = e.target.value.toLocaleUpperCase();
		this.setState({
			plateNumber: newState,
			searchResult: {},
			viewState: ''
		}, () => {
			this.handlePlateNumberValidation();
		});
	}

	handlePlateNumberValidation = () => {
		if (this.validatePlateNumber) {
			const rules = {
				plateNumber: 'required|plateNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/carriagewarrantyblock/regnrerror'),
				plateNumber: TranslationService.getTranslation('/carriagewarrantyblock/regnrerror')
			}

			let stateObject = ValidationService.validate(this.state.plateNumber, rules, customErrorMessages);
			this.setState({ plateNumber: stateObject });
		}
	}

	handleSubmit = () => {
		this.validatePlateNumber = true;
		this.handlePlateNumberValidation();

		if (!ValidationService.stateHasError(this.state)) {
			this.getDamageWarrantyStatus();
		}
	}

	isWarrantyActive = () => {
		let activeWarranty = _.find(this.state.searchResult.warranties, (warranty) => {
			return ((warranty.productType === this.TOYOTA_WARRANTY_PRODUCTTYPE ||
				warranty.productType === this.LEXUS_WARRANTY_PRODUCTTYPE ||
				warranty.productType === this.SUZUKI_WARRANTY_PRODUCTTYPE) &&
				!warranty.hasExpired);
		});

		return activeWarranty;
	}

	getDamageWarrantyStatus = () => {
		CarriageWarrantyResource.getDamageWarrantyStatus(this.state.plateNumber.value).then((response) => {

			let brandAndModel = null;
			if (response.hasOwnProperty("brandAndModel")) {
				brandAndModel = response.brandAndModel;
			}
			let warranties = [];

			_.forOwn(response.warranties, (warranty) => {
				warranties.push({
					name: this.formatWarrantyName(warranty.name),
					expiryDate: this.formatDate(warranty.expiryDate),
					productType: warranty.productType,
					hasExpired: warranty.hasExpired
				});
			});

			this.setState({
				searchResult: {
					brandAndModel: brandAndModel,
					warranties: warranties
				},
				viewState: this.viewStateConstants.SUCCESS
			});
		}, (error) => {
			let errorObject = {
				value: true
			}
			if (error.errors && !_.isEmpty(error.errors)) {
				errorObject.errorCode = error.errors[0].code;
			}
			this.setState({
				respondedWithError: errorObject,
				viewState: this.viewStateConstants.ERROR});
		});
	}

	getSuccessHeading = () => {
		if (this.isWarrantyActive()) {
			return  TranslationService.getTranslation('/carriagewarrantyblock/successheading1'+window.siteConfigObject.currentBrand)
					+ this.state.plateNumber.value
					+ TranslationService.getTranslation('/carriagewarrantyblock/successheading2'+window.siteConfigObject.currentBrand);
		} else {
			return  TranslationService.getTranslation('/carriagewarrantyblock/failheading1'+window.siteConfigObject.currentBrand)
					+ this.state.plateNumber.value
					+ TranslationService.getTranslation('/carriagewarrantyblock/failheading2'+window.siteConfigObject.currentBrand);
		}
	}

	getBrandAndModelText = () => {
		return TranslationService.getTranslation('/carriagewarrantyblock/model') + this.state.searchResult.brandAndModel;
	}

	getWarrantyText = (warrantyItem) => {
		if (warrantyItem.hasExpired) {
			return warrantyItem.name + TranslationService.getTranslation('/carriagewarrantyblock/warrantyactivetext') + warrantyItem.expiryDate;
		} else {
			return warrantyItem.name + TranslationService.getTranslation('/carriagewarrantyblock/warrantyexpiredtext') + warrantyItem.expiryDate;
		}
	}

	getReportDamageUrl = () => {
		if (this.isWarrantyActive()) {
			return window.carriageWarrantyBlockObject.reportDamageLink;
		}
		return window.carriageWarrantyBlockObject.externalReportDamageLink;
	}

	getExternalReportDamageUrl = () => {
		return window.carriageWarrantyBlockObject.externalReportDamageLink;
	}

	getGetDamageWarrantyStatusUrl = () => {
		return window.apiReferences.getDamageWarrantyStatusUrl;
	}

	getShowReportDamageButton = () => {
		return window.carriageWarrantyBlockObject.showReportDamageButton;
	}

	getHeading = () => {
		return window.carriageWarrantyBlockObject.heading;
	}

	getErrorCodeForRequest = () => {
		let errorTranslation = TranslationService.getTranslation('/carriagewarrantyblock/responseerrorcode' + this.state.respondedWithError.errorCode);
		return !_.isEmpty(errorTranslation) ? errorTranslation : TranslationService.getTranslation('/carriagewarrantyblock/responseerror');
	}

	formatWarrantyName = string => {
		return string
			.replace(/(\B)[^ ]*/g,match =>(match.toLowerCase()))
			.replace(/^[^ ]/g,match=>(match.toUpperCase()));
	}

	formatDate = dateString => {
		return dateString.slice(0, 10);
	}

	render() {
		const searchButtonClass = "btn no-label-spacer btn-search " + (this.state.viewState === this.viewStateConstants.SUCCESS ? "btn-secondary" : "btn-primary");
		return (
			<div className="carriage-warranty-block">
				{this.getHeading().length > 0 &&
					<div className="text-center">
						<h2 className="mb-1">{this.getHeading()}</h2>
					</div>
				}

				<div className="row">
					<div className="col-lg-6">
						<Input id="plateNumber"
								ariaLabel={TranslationService.getTranslation('/carriagewarrantyblock/regnr/arialabel')}
								label={TranslationService.getTranslation('/carriagewarrantyblock/regnr')}
								value={this.state.plateNumber.value}
								error={this.state.plateNumber.error}
								plateInput={true}
								maxLength={6}
								formGroupClass={'mb-0'}
								onChange={this.handlePlateNumberChange}
								onValidate={this.handlePlateNumberValidation}
								onKeyPress={this.handleSubmit}/>
					</div>
					<div className="col-lg-6">
						<button className={searchButtonClass} type="submit" onClick={this.handleSubmit}>{TranslationService.getTranslation('/carriagewarrantyblock/searchbutton')}</button>
					</div>
				</div>

				{this.state.viewState.length > 0 &&
					<hr className="mt-2" />
				}

				{(() => {
					switch (this.state.viewState) {
					case this.viewStateConstants.SUCCESS:
						return (
							<div className="row">
								<div className="col-12 mb-1 mt-1">
									<span className="d-block font-weight-bold mb-1">{this.getSuccessHeading()}</span>
									{this.state.searchResult.brandAndModel &&
										<span className="d-block">{this.getBrandAndModelText()}</span>
									}

									{!_.isEmpty(this.state.searchResult.warranties) && this.state.searchResult.warranties.map((item, key) => {
										return (
											<span className="d-block" key={'warranty' + key}>{this.getWarrantyText(item)}</span>
										);
									})}

									{_.isEmpty(this.state.searchResult.warranties) &&
										<span className="d-block text-danger">{TranslationService.getTranslation('/carriagewarrantyblock/nowarranties'+window.siteConfigObject.currentBrand)}</span>
									}
								</div>

								{this.getShowReportDamageButton() &&
									<div className="col-12">
										<a className="btn btn-primary" href={this.getReportDamageUrl()}>{TranslationService.getTranslation('/carriagewarrantyblock/reportdamagebutton')}</a>
									</div>
								}
							</div>
						);
					case this.viewStateConstants.ERROR:
						return (
							<div>
								{this.state.respondedWithError.value &&
									<div className="col-12">
										<div className="text-center text-danger font-weight-bold">
											<span>
												{this.getErrorCodeForRequest()}
											</span>
										</div>
									</div>
								}
								{!this.state.respondedWithError.value &&
									<div className="row mt-2 text-center">
										<div className="col-12">
											<h3>{TranslationService.getTranslation('/carriagewarrantyblock/error')}</h3>
										</div>
									</div>
								}
							</div>
						);
					default:
						return null;
					}
				})()}
			</div>
		);
	}
}

export default CarriageWarrantyBlockComponent;