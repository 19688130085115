﻿import React, { Component } from 'react';

import TranslationService from "../../../../shared/services/TranslationService";

class NeedHelpComponentView extends Component {

	getPhoneNumber = () => {
		return window.reportDamageFormAppObject.phoneNumber;
	}

	getPhoneLink = () => {
		return window.reportDamageFormAppObject.phoneLink;
	}

	getPhoneNumberOffHours = () => {
		return window.reportDamageFormAppObject.phoneNumberOffHours;
	}
	
	getPhoneNumberOffHoursLink = () => {
		return window.reportDamageFormAppObject.phoneNumberOffHoursLink;
	}

	getOffHoursSection = () => {
		if (window.reportDamageFormAppObject.brand == "Toyota" || window.reportDamageFormAppObject.brand == "Lexus") {
			return (
				<div>
					<div>{TranslationService.getTranslation('/reportdamageformpage/help/offhours')}</div>
					<a href={this.getPhoneNumberOffHoursLink()}>{this.getPhoneNumberOffHours()}</a>
				</div>
			);
		}
		else {
			return null;
		}
	}

	render() {
		const {translations} = this.props;

		return (
			<div className="col-lg-4">
				<aside className="help-bubble">
					<h3>{TranslationService.getTranslation('/reportdamageformpage/help/heading')}</h3>
	
					<div className="help-bubble-phone">
						<div className="help-bubble-phone-icon"></div>
						<a href={this.getPhoneLink()}>{this.getPhoneNumber()}</a>
					</div>

					{this.getOffHoursSection()}
				</aside>
			</div>
		);
	}
}

export default NeedHelpComponentView;