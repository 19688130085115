﻿var $ = require("jquery");
import React, { Component } from 'react';

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined,
};

const defaultProps = {
	showText: 'link_text',
	hiddenText: 'link_text',
	id: '',
	value: false,
	error: ''
};

class ExampleLink extends Component {

	constructor() {
		super();
		this.state = {
			linkText: ''
		}
	}

	componentDidMount() {
		this.setState({
			linkText: this.props.showText
		});

		$('#' + this.props.id).on('show.bs.collapse', () => {
			this.setState({
				linkText: this.props.hiddenText
			});
		});

		$('#' + this.props.id).on('hide.bs.collapse', () => {
			this.setState({
				linkText: this.props.showText
			});
		});
	}

	render() {
		const {id, children} = this.props;

		return (
			<div className="mb-1">
				<button data-target={'#' + id} type="button" className="btn btn-link btn-example" data-toggle="collapse">{this.state.linkText}</button>

				<div className="collapse" id={id}>
					{children}
				</div>
			</div>
		);
	}
}

ExampleLink.defaultProps = defaultProps;

export default ExampleLink;