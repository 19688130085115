﻿var Promise = require("bluebird");

// Lodash functions
let find = require('lodash/find');

const _ = {
	find: find
};

class TranslationService {

	fetchTranslations = () => {
		let url = window.location.origin + '/api/i18n/translations';

		return new Promise(function(resolve, reject) {
			$.get(url, (result, status) => {
				resolve(result);
			}).fail(function(jqXHR, textStatus, errorThrown) {
				reject(textStatus);
			});
		});
	}

	getTranslation = (key) => {
		return window.translations[key];
	}
}

export default new TranslationService();