﻿import React, { Component } from 'react';

import Input from "./Input";
import TranslationService from "../services/TranslationService";

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined
};

class ContactInput extends Component {

	constructor() {
		super();
	}

	handleSsnChange = (e) => {
		if (this.props.onSsnChange) {
			this.props.onSsnChange(e);
		}
	};

	handlePhoneNumberChange = (e) => {
		if (this.props.onPhoneNumberChange) {
			this.props.onPhoneNumberChange(e);
		}
	};

	handleEmailChange = (e) => {
		if (this.props.onEmailChange) {
			this.props.onEmailChange(e);
		}
	};

	handleConfirmEmailChange = (e) => {
		if (this.props.onConfirmEmailChange) {
			this.props.onConfirmEmailChange(e);
		}
	};

	handleSsnValidation = (e) => {
		if (this.props.onSsnValidation) {
			this.props.onSsnValidation(e);
		}
	};

	handlePhoneNumberValidation = (e) => {
		if (this.props.onPhoneNumberValidation) {
			this.props.onPhoneNumberValidation(e);
		}
	};

	handleEmailValidation = (e) => {
		if (this.props.onEmailValidation) {
			this.props.onEmailValidation(e);
		}
	};

	render() {
		const {ssn, phoneNumber, email, confirmEmail} = this.props;

		return (
			<div className="mt-2">
				<h3 className="contact-input-header">{TranslationService.getTranslation('/reportdamageformpage/step2/contactheading')}</h3>
				<p>{TranslationService.getTranslation('/reportdamageformpage/step2/contactpreamble')}</p>

				<div className="row">
					<div className="col-sm-6">
						<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/pnr')}
								id="ssn"
								value={ssn.value}
								error={ssn.error}
								onChange={this.handleSsnChange}
						        onValidate={this.handleSsnValidation}/>
					</div>
					<div className="col-sm-6">
						<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/phone')}
								id="phoneNumber"
								value={phoneNumber.value}
								error={phoneNumber.error}
								onChange={this.handlePhoneNumberChange}
						        onValidate={this.handlePhoneNumberValidation}/>
					</div>
					<div className="col-sm-6">
						<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/email')}
								id="email"
								value={email.value}
								error={email.error}
								onChange={this.handleEmailChange}
						        onValidate={this.handleEmailValidation}/>
					</div>
					<div className="col-sm-6">
						<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/confirmemail')}
								id="confirmEmail"
								value={confirmEmail.value}
								error={confirmEmail.error}
								onChange={this.handleConfirmEmailChange}
								onValidate={this.handleEmailValidation}/>
					</div>
				</div>
			</div>
		);
	}
}

export default ContactInput;