﻿import React, { Component } from 'react';

import Input from "../../../../shared/components/Input";
import RadioGroup from "../../../../shared/components/RadioGroup";
import TextArea from "../../../../shared/components/TextArea";
import TranslationService from "../../../../shared/services/TranslationService";

// Lodash functions
let isEmpty = require('lodash/isEmpty');

const _ = {
	isEmpty: isEmpty
};

class OtherInvolvedComponentView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			otherInvolvedObjectValue: this.props.otherInvolvedObject.value,
			otherInvolvedList: this.props.otherInvolvedList
		}
	}

	handleOtherInvolvedChange = (e) => {
		if (e.target.value === '1') {
			if (this.state.otherInvolvedList.length === 0) {
				this.addOtherInvolvedToList();
			}
		}

		if (e.target.value === '0') {
			this.state.otherInvolvedList = [];
			this.setState(this.state);
		}

		this.setState({otherInvolvedObjectValue: e.target.value});
		if (this.props.handleOtherInvolvedChange) {
			this.props.handleOtherInvolvedChange(e);
		}
		if (this.props.handleOtherInvolvedListChange) {
			this.props.handleOtherInvolvedListChange(this.state.otherInvolvedList);
		}
	}

	handleOtherInvolvedListPlatenumberChange = (item, e) => {
		item.value = e.target.value.toLocaleUpperCase();
		this.setState(this.state, () => {
			// Callback
			if (this.props.handleOtherInvolvedListChange) {
				this.props.handleOtherInvolvedListChange(this.state.otherInvolvedList);
			}
		});
	}

	handleOtherInvolvedListContactInformationChange = (item, e) => {
		item.value = e.target.value;
		this.setState(this.state, () => {
			// Callback
			if (this.props.handleOtherInvolvedListChange) {
				this.props.handleOtherInvolvedListChange(this.state.otherInvolvedList);
			}
		});
	}

	addOtherInvolvedToList = () => {
		let newInvolved = {
			plateNumber: {
				value: '',
				error: ''
			},
			contactInformation: {
				value: '',
				error: ''
			}
		};

		this.state.otherInvolvedList.push(newInvolved);
		this.setState(this.state, () => {
			$('#otherInvolvedItem'+(this.state.otherInvolvedList.length-1)).collapse('show');
		});

		// Callback
		if (this.props.handleOtherInvolvedListChange) {
			this.props.handleOtherInvolvedListChange(this.state.otherInvolvedList);
		}
	}

	removeOtherInvolvedFromList = (index, event) => {
		if (event) {
			event.preventDefault();
		}

		this.state.otherInvolvedList.splice(index, 1);
		this.setState(this.state);

		// Callback
		if (this.props.handleOtherInvolvedListChange) {
			this.props.handleOtherInvolvedListChange(this.state.otherInvolvedList);
		}
	}

	render() {
		const {radioButtonTranslationKey, radioButtonInformationTranslationKey, otherInvolvedObject} = this.props;

		return (
			<div>
				<div className="row">
					<div className="col-12">
						<RadioGroup label={TranslationService.getTranslation(radioButtonTranslationKey)}
									id="otherInvolved"
									value={otherInvolvedObject.value}
									error={otherInvolvedObject.error}
									items={otherInvolvedObject.items}
									inline={true}
									information={TranslationService.getTranslation(radioButtonInformationTranslationKey)}
									onChange={this.handleOtherInvolvedChange}/>
					</div>
				</div>

				{!_.isEmpty(this.state.otherInvolvedObjectValue) && !!+this.state.otherInvolvedObjectValue &&
					<div className="accordion" id="otherInvolvedParent">
						{this.state.otherInvolvedList.map((item, key) => {
							return (
								<div key={'otherInvolvedList' + key} className="bg-light mb-1">
									<div className="p-1 d-flex">
										<a className="link link-chevron" data-toggle="collapse" href={'#otherInvolvedItem' + key}>Motpart {key+1}</a>
										<div className="ml-auto">
											{this.state.otherInvolvedList.length > 1 &&
												<a className="link text-xs" onClick={this.removeOtherInvolvedFromList.bind(this, key)} href={'#otherInvolvedItem' + key}>Ta bort</a>
											}
										</div>
									</div>
									<div className="collapse" data-parent="#otherInvolvedParent" id={'otherInvolvedItem' + key}>
										<div className="p-1">
											<div className="row">
												<div className="col-12">
													<Input label={TranslationService.getTranslation('/startpage/barker/regnr')}
													       id={'otherInvolvedListPlatenumber' + key}
													       value={item.plateNumber.value}
													       error={item.plateNumber.error}
													       plateInput={true}
													       placeholder={TranslationService.getTranslation('/placeholders/regnr')}
													       onChange={this.handleOtherInvolvedListPlatenumberChange.bind(this, item.plateNumber)}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-12">
													<TextArea label={TranslationService.getTranslation('/reportdamageformpage/step2/injuryinvehicledescription')}
																id={'otherInvolvedListContactInformation' + key}
																value={item.contactInformation.value}
																error={item.contactInformation.error}
																onChange={this.handleOtherInvolvedListContactInformationChange.bind(this, item.contactInformation)}
																removeBottomPadding={true}/>
												</div>
											</div>
										</div>
									</div>

								</div>
							);
					})}
					{this.state.otherInvolvedList.length !== 10 &&
						<button type="button" className="btn btn-light mb-1" onClick={this.addOtherInvolvedToList}>Lägg till motpart</button>
					}
					</div>
				}
			</div>
		);
	}
}

export default OtherInvolvedComponentView;