﻿import TranslationService from "./TranslationService";

let find = require('lodash/find');

const _ = {
	find: find
};

class ChoiceService {
	getBoolChoices = (translations) => {
		return [
			{
				label: TranslationService.getTranslation('/forms/radioyes'),
				value: '1'
			},
			{
				label: TranslationService.getTranslation('/forms/radiono'),
				value: '0'
			}
		];
	}

	getLabelForValue = (items, value) => {
		let item = _.find(items, (choice) => {
			return choice.value === value;
		});
		return item ? item.label : "";
	}
}

	export default new ChoiceService();