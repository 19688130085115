﻿import React from 'react';
import ReactDOM from 'react-dom';

import CarriageWarrantyBlockComponent from "./components/carriageWarrantyBlockComponent";

if (document.getElementsByClassName('carriage-warranty-block-app')) {
	$('.carriage-warranty-block-app').each(function() {
		ReactDOM.render(
			<CarriageWarrantyBlockComponent/>,
			this
		);
	});
}