﻿$(document).ready(function() {
	var elements = document.getElementsByClassName('video-block');
	setHeight(elements);

	window.addEventListener('resize', function () {
		setHeight(elements);
	});
});

function setHeight(elementList) {
	for (var i = 0; i < elementList.length; i++) {
		var newHeight = (316 / 560) * elementList[i].offsetWidth;
		elementList[i].style.height = newHeight + 'px';
	}
}