﻿var $ = require("jquery");
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Checkbox from "../../../shared/components/Checkbox";
import TranslationService from "../../../shared/services/TranslationService";
import ValidationService from "../../../shared/services/ValidationService";
import NeedHelpComponentView from "./views/NeedHelpComponentView";
import ErrorViewService from "../../../shared/services/ErrorViewService";
import TrackingService from "../../../shared/services/TrackingService";
import CreateClaimResource from "../../resources/CreateClaimResource";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');
let extend = require('lodash/extend');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
	extend: extend
};

class SummaryPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			acceptTerms: {value: false,error: ''},

			translations: []
		};

		this.submitFired = false;
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let newState = {};
		_.extend(newState, this.state, this.props.history.location.state);
		this.setState(newState);

		TrackingService.pushPageViewEvent("Skadeanmälan",
			"Skadeanmälan - Granska dina uppgifter",
			"3",
			"Skadeanmälan märkeswebb",
			"Skadeanmälan",
			"Fordon",
			this.props.sharedInfo.trackingName,
			this.props.sharedInfo.trackingExtent);
	}

	handleAcceptTermsChange = e => {
		let newState = $.extend({}, this.state.acceptTerms);
		newState.value = e.target.checked;

		let state = {};
		state.acceptTerms = newState;
		this.setState(state, () => {
			this.handleAcceptTermsValidation();
		});
	}

	handleAcceptTermsValidation = () => {
		if (this.submitFired) {
			let rules = {};
			rules.acceptTerms = 'accepted';

			let customErrorMessages = {
				accepted: TranslationService.getTranslation('/reportdamageformpage/step3/agreeerror')
			};

			let state = {};
			state.acceptTerms = ValidationService.validate(this.state.acceptTerms, rules, customErrorMessages);
			this.setState(state);
		}
	}

	handleGoBack = () => {
		var stateWithoutErrors = this.state;
		_.forOwn(stateWithoutErrors, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				stateObject.error = "";
			}
		});

		let previousPagePath = '';
		switch(this.props.sharedInfo.damageType) {
			case 'vehicleDamage':
				previousPagePath = '/skada-pa-fordon';
				break;
			case 'theft':
				previousPagePath = '/inbrott-och-stold';
				break;
			case 'mechanicalDamage':
				previousPagePath = '/maskinskada';
				break;
			default:
				break;
		}

		this.props.history.push({
			pathname: previousPagePath,
			state: stateWithoutErrors
		});
	}

	handleSubmit = () => {
		this.submitFired = true;
		this.handleAcceptTermsValidation();

		if (!this.hasErrorInForm()) {
			let data = this.mergePostData();
			CreateClaimResource.sendClaim(data).then(() => {
				console.log(data);
				this.props.history.push({
					pathname: '/kvitto',
					state: this.state
				});
			}, (error) => {
				console.log(error);
			});
		} else {
			ErrorViewService.scrollToFirstError();
		}
	}

	mergePostData = () => {
		var data = {
			customerEmail: this.props.sharedInfo.email,
			blocks: []
		}

		var selectDamageTypeBlock = {
			code: 'null',
			header: TranslationService.getTranslation('/reportdamageformpage/email/damagetypeheading'),
			questions: []
		}

		var damageInformationBlock = {
			code: 'null',
			header: TranslationService.getTranslation('/reportdamageformpage/email/damageinformationheading'),
			questions: []
		}

		var contactInformationBlock = {
			code: 'null',
			header: TranslationService.getTranslation('/reportdamageformpage/email/contactinformationheading'),
			questions: []
		}

		this.props.postData.selectDamageType.map((item) => {
			selectDamageTypeBlock.questions.push({
				code: 'null',
				question: item.label,
				answer: item.value
			});
		});

		this.props.postData.damageInformation.map((item) => {
			damageInformationBlock.questions.push({
				code: 'null',
				question: item.label,
				answer: item.value
			});
		});

		this.props.postData.contactInformation.map((item) => {
			contactInformationBlock.questions.push({
				code: 'null',
				question: item.label,
				answer: item.value
			});
		});

		data.blocks.push(selectDamageTypeBlock);
		data.blocks.push(damageInformationBlock);
		data.blocks.push(contactInformationBlock);
		return data;
	}

	hasErrorInForm() {
		var result = false;
		_.forOwn(this.state, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				result = true;
			}
		});

		return result;
	}

	render() {
		const {postData} = this.props;

		if (_.isEmpty(this.props.sharedInfo.damageType)) {
			return (
				<Redirect to="/"/>
			);
		} else {
			return (
				<div className="report-damage-form-page-container">
					<h1>{TranslationService.getTranslation('/reportdamageformpage/step3/heading')}</h1>
					<p className="lead">{TranslationService.getTranslation('/reportdamageformpage/step3/preamble')}</p>

					<div className="row">
						<div className="col-lg-8">
							<div className="container p-2 bg-white">
								<div className="row">
									<div className="col-12 z-index-1">
										<ul className="progressbar">
											<li className="success">{TranslationService.getTranslation('/reportdamageformpage/step1/progress_text')}</li>
											<li className="success">{TranslationService.getTranslation('/reportdamageformpage/step2/progress_text')}</li>
											<li className="active">{TranslationService.getTranslation('/reportdamageformpage/step3/progress_text')}</li>
											<li>{TranslationService.getTranslation('/reportdamageformpage/step4/progress_text')}</li>
										</ul>
									</div>
								</div>

								<hr className="mb-2"/>

								<div className="row">
									<div className="col-12 form-summary-group mb-2">
										<h3 className="mb-0">{TranslationService.getTranslation('/reportdamageformpage/step3/headingincident')}</h3>
										{postData.selectDamageType.length &&
											postData.selectDamageType.map((item, index) => (
												<div className="form-summary-group-row" key={index}>
													<div className="form-summary-group-row-cell font-weight-bold">
														{item.label}
													</div>
													<div className="form-summary-group-row-cell">
														{item.value}
													</div>
												</div>
											))
										}
										{postData.damageInformation.length &&
											postData.damageInformation.map((item, index) => (
												<div className="form-summary-group-row" key={index}>
													<div className="form-summary-group-row-cell font-weight-bold">
														{item.label}
													</div>
													<div className="form-summary-group-row-cell">
														{item.value}
													</div>
												</div>
											))
										}
									</div>
								</div>

								<div className="row">
									<div className="col-12 form-summary-group mb-2">
										<h3 className="mb-0">{TranslationService.getTranslation('/reportdamageformpage/step3/headingcontact')}</h3>
										{postData.contactInformation.length &&
											postData.contactInformation.map((item, index) => (
												<div className="form-summary-group-row" key={index}>
													<div className="form-summary-group-row-cell font-weight-bold">
														{item.label}
													</div>
													<div className="form-summary-group-row-cell">
														{item.value}
													</div>
												</div>
											))
										}
									</div>
								</div>

								<div className="row">
									<div className="col-12 form-summary-group mb-2">
										<h3 className="mb-15 form-header">{TranslationService.getTranslation('/reportdamageformpage/step3/headingagree')}</h3>
										<div className="px-1">
											<Checkbox   text={TranslationService.getTranslation('/reportdamageformpage/step3/agree')}
														id="acceptTerms"
														value={this.state.acceptTerms.value}
														error={this.state.acceptTerms.error}
														onChange={this.handleAcceptTermsChange}/>
										</div>
									</div>
								</div>

								<hr/>

								<div className="row pt-2">
									<div className="col-sm-6 mb-1 text-left">
										<button type="button" className="btn btn-light btn-cancel" onClick={this.handleGoBack}>
											{TranslationService.getTranslation('/reportdamageformpage/button/back')}
										</button>
									</div>
									<div className="col-sm-6 text-right">
										<button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>
											{TranslationService.getTranslation('/reportdamageformpage/step3/buttoncontinue')}
										</button>
									</div>
								</div>
							</div>
						</div>
						<NeedHelpComponentView translations={this.state.translations}/>
					</div>
				</div>
			);
		}
	}
}

export default SummaryPage;