﻿import React from 'react';
import ReactDOM from 'react-dom';

import ContactUsComponent from "./components/contactUsComponent";

if (document.getElementsByClassName('contact-us-form')) {
	$('.contact-us-form').each(function() {
		ReactDOM.render(
			<ContactUsComponent/>,
			this
		);
	});
}
