﻿import React, { Component } from 'react';

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined
};

class RadioGroup extends Component {

	constructor() {
		super();
		this.dirty = false;
	}

	onChange = (e) => {
		this.dirty = true;
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	};

	onKeyPress = (e) => {
		if (this.props.onKeyPress && e.key === 'Enter') {
			this.props.onKeyPress(e);
		}
	};

	_shouldAddValidClass = () => {
		const {value, error} = this.props;
		return (this.dirty && _.isUndefined(value)) || (!_.isEmpty(value) && (_.isUndefined(error) || _.isEmpty(error)));
	};

	render() {
		const {label, id, error, items, inline, information, value} = this.props;

		return (
			<div className={'form-group ' + (!_.isUndefined(error) && !_.isEmpty(error) ? ' has-danger' : '')}>
				<label className="radio-group-label">{label}</label>

				{!inline && items.map((item, key) => {
						return (
							<div className="form-check" key={id + key}>
								<input type="radio" 
										className="form-check-input" 
										tabIndex="0"
										name={id} 
										id={id + key} 
										value={item.value}
										checked={item.value === value}
										onChange={this.onChange}
										onKeyPress={this.onKeyPress}/>
								<label htmlFor={id + key} className="form-check-label">
									{item.label}
									{!_.isUndefined(item.information) && !_.isEmpty(item.information) &&
										<span className="form-check-info">
											{item.information}
										</span>
									}
								</label>
							</div>
						);
					})
				}

				{inline &&
					<div>
						<div className="d-flex">
							{items.map((item, key) => {
									return (
										<div className={'form-check ' + (items.length !== key ? 'mr-1 ' : '') +  (!_.isUndefined(information) && !_.isEmpty(information) ? 'mb-05' : 'mb-0')} key={id + key}>
											<input type="radio" 
													className="form-check-input" 
													name={id} 
													id={id + key} 
													value={item.value}
													checked={item.value === value}
													onChange={this.onChange}/>
											<label htmlFor={id + key} className="form-check-label">
												{item.label}
											</label>
										</div>
									);
								})
							}
						</div>
						<span className={'form-check-info text-xs'}>
							{information}
						</span>
					</div>
				}

				<span className="field-validation-error mt-1">
					<span>
						{error}
					</span>
				</span>
			</div>
		);
	}
}

export default RadioGroup;