﻿import React, { Component } from 'react';

import Input from "../../components/Input.js";
import TranslationService from "../../services/TranslationService";
import ValidationService from "../../services/ValidationService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty
};

class ReportDamageBarkerComponent extends Component {

	constructor() {
		super();
		this.viewStateConstants = {
			SUCCESS: 'SUCCESS',
			ERROR: 'ERROR'
		}
		this.state = {
			viewState: '',
			plateNumber: {
				value: '',
				error: ''
			},
			searchResult: {
			},
			translations: []
		}
		this.validatePlateNumber = false;
	}

	handlePlateNumberChange = e => {
		if (e.target.value.length === 6) {
			this.validatePlateNumber = true;
		}

		let newState = $.extend({}, this.state.plateNumber);
		newState.value = e.target.value;
		this.setState({
			plateNumber: newState,
			searchResult: {},
			viewState: ''
		}, () => {
			this.handlePlateNumberValidation();
		});
	}

	handlePlateNumberValidation = () => {
		if (this.validatePlateNumber) {
			const rules = {
				plateNumber: 'required|plateNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/startpage/barker/regnrerror'),
				plateNumber: TranslationService.getTranslation('/startpage/barker/regnrwrongformaterror')
			}

			let stateObject = ValidationService.validate(this.state.plateNumber, rules, customErrorMessages);
			this.setState({ plateNumber: stateObject });
		}
	}

	handleSubmit = () => {
		this.validatePlateNumber = true;
		this.handlePlateNumberValidation();

		if (!ValidationService.stateHasError(this.state)) {

			if (this.state.plateNumber.value === 'TTT123') {
				var result = {
					brandAndModel: 'Toyota Rav4',
					warranties: [{
							expiryDate: '2020-01-01',
							name: 'Vagnskadegaranti',
							productType: 'TVG'
						}
					]
				}

				this.setState({
					viewState: this.viewStateConstants.SUCCESS,
					searchResult: result
				});
			} else if (this.state.plateNumber.value === 'TTT000') {
				var result = {
					brandAndModel: 'Toyota Auris 2006',
					warranties: [{
							expiryDate: '2001-01-01',
							name: 'Vagnskadegaranti',
							productType: 'TVG'
						},{
							expiryDate: '2006-01-01',
							name: 'Eurocare',
							productType: 'EU'
						}
					]
				}

				this.setState({
					viewState: this.viewStateConstants.SUCCESS,
					searchResult: result
				});
			} else {
				this.setState({ viewState: this.viewStateConstants.ERROR });
			}
		}
	}

	shouldShowWorkshopButton = () => {
		return this.state.viewState === this.viewStateConstants.SUCCESS;
	}

	isWarrantyActive = () => {
		if (this.state.plateNumber.value === 'TTT123') {
			return true;
		} else if (this.state.plateNumber.value === 'TTT000') {
			return false;
		}
	}

	getSuccessHeading = () => {
		if (this.isWarrantyActive()) {
			return this.state.plateNumber.value + ' ' + TranslationService.getTranslation('/reportdamage/barker/warrantyactive');
		} else {
			return this.state.plateNumber.value + ' ' + TranslationService.getTranslation('/reportdamage/barker/warrantyexpired');
		}
	}

	getBrandAndModelText = () => {
		return TranslationService.getTranslation('/reportdamage/barker/model') + ' ' + this.state.searchResult.brandAndModel;
	}

	getWarranyText = (warrentyItem) => {
		if (this.isWarrantyActive()) {
			return warrentyItem.name + ' ' + TranslationService.getTranslation('/reportdamage/barker/warrantyactivetext') + ' ' + warrentyItem.expiryDate;
		} else {
			return warrentyItem.name + ' ' + TranslationService.getTranslation('/reportdamage/barker/warrantyexpiredtext') + ' ' + warrentyItem.expiryDate;
		}
	}

	getButtonText = () => {
		if (this.isWarrantyActive()) {
			return TranslationService.getTranslation('/reportdamage/barker/reportdamagebutton');
		} else {
			return TranslationService.getTranslation('/reportdamage/barker/redirecttodamagebutton');
		}
	}

	getNextActionUrl = () => {
		if (this.isWarrantyActive()) {
			return window.reportDamageBarkerObject.reportDamageLink;
		} else {
			return window.reportDamageBarkerObject.externalReportDamageLink;
		}
	}

	render() {
		return (
			<div className="report-damage-barker">
				<div className="text-center">
					<h2 className="mb-1">{TranslationService.getTranslation('/reportdamage/barker/heading')}</h2>
				</div>

				<div className="row">
					<div className="col-12 col-lg-8 pb-1 pb-lg-0">
						<Input	label={TranslationService.getTranslation('/reportdamage/barker/platenumberlabel')}
								id="plateNumber"
								value={this.state.plateNumber.value}
								error={this.state.plateNumber.error}
								plateInput={true}
								maxLength={6}
								formGroupClass={'mb-05'}
								onChange={this.handlePlateNumberChange}
								onValidate={this.handlePlateNumberValidation}/>
					</div>
					<div className="col-12 col-lg-4">
						<button className="report-damage-barker-search-button" onClick={this.handleSubmit}>{TranslationService.getTranslation('/reportdamage/barker/button')}</button>
					</div>
				</div>

				{this.state.viewState.length > 0 &&
					<hr className="mt-2" />
				}

				{(() => {
					switch (this.state.viewState) {
					case this.viewStateConstants.SUCCESS:
						return (
							<div className="row mb-2">
								<div className="col-12 mb-1 mt-1">
									<span className="d-block font-weight-bold">{this.getSuccessHeading()}</span>
									<span className="d-block">{this.getBrandAndModelText()}</span>

									{!_.isEmpty(this.state.searchResult.warranties) && this.state.searchResult.warranties.map((item, key) => {
										return (
											<span className="d-block" key={'warrany' + key}>{this.getWarranyText(item)}</span>
										);
									})}

								</div>
								<div className="col-12">
									<a className="report-damage-barker-action-button btn-block" href={this.getNextActionUrl()}>{this.getButtonText()}</a>
								</div>
							</div>
						);
					case this.viewStateConstants.ERROR:
						return (
							<div className="row mb-2 mt-2 text-center">
								<div className="col-12">
									<h3>{TranslationService.getTranslation('/reportdamage/barker/error')}</h3>
								</div>
							</div>
						);
					default:
						return null;
					}
				})()}

				{this.shouldShowWorkshopButton() &&
					<div className="row mb-1">
						<div className="col-12 mb-05">
							<h3>{TranslationService.getTranslation('/reportdamage/barker/workshopheading')}</h3>
						</div>
						<div className="col-12">
							<a target="_blank" className="report-damage-barker-workshop-button btn-block" href={window.reportDamageBarkerObject.mapUrl}>{TranslationService.getTranslation('/reportdamage/barker/workshopbutton')}</a>
						</div>
					</div>
				}

				{this.state.viewState !== this.viewStateConstants.SUCCESS &&
					<div className="text-center pt-2">
						<a href={'tel:'+window.reportDamageBarkerObject.formattedReportDamagePhoneNumber} className="report-damage-block-phone">
							{TranslationService.getTranslation('/reportdamage/barker/call') + ' ' + window.reportDamageBarkerObject.reportDamagePhoneNumber}
						</a>
					</div>
				}
			</div>
		);
	}
}

export default ReportDamageBarkerComponent;