﻿import React, { Component } from 'react';

import Input from "../../components/Input.js";
import TextArea from "../../components/TextArea.js";
import TranslationService from "../../services/TranslationService";
import ValidationService from "../../services/ValidationService";
import TrackingService from "../../services/TrackingService";
import ContactUsResource from "../../resources/ContactUsResource";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
};

class ContactUsComponent extends Component {
	
	constructor() {
		super();
		this.state = {
			firstName: {
				value: '',
				error: ''
			},
			lastName: {
				value: '',
				error: ''
			},
			email: {
				value: '',
				error: ''
			},
			phone: {
				value: '',
				error: ''
			},
			message: {
				value: '',
				error: ''
			},
			translations: [],
			showThankYouPage: false,
			respondedWithError: {
				value: false,
				errorCode: '',
			}
		};

		this.postData = {
			companyId: '28', // Hardcoded to stockholm
			firstName: '',
			lastName: '',
			email: '',
			message: '',
			phone: ''
		}

		this.submitFired = false;
	}

	handleFirstNameChange = e => {
		let newState = $.extend({}, this.state.firstName);
		newState.value = e.target.value;
		this.postData.firstName = e.target.value;
		this.setState({firstName: newState}, () => {
			this.handleFirstNameValidation();
		});
	}

	handleLastNameChange = e => {
		let newState = $.extend({}, this.state.lastName);
		newState.value = e.target.value;
		this.postData.lastName = e.target.value;
		this.setState({lastName: newState}, () => {
			this.handleLastNameValidation();
		});
	}

	handleEmailChange = e => {
		let newState = $.extend({}, this.state.email);
		newState.value = e.target.value;
		this.postData.email = e.target.value;
		this.setState({email: newState}, () => {
			this.handleEmailValidation();
		});
	}

	handlePhoneChange = e => {
		let newState = $.extend({}, this.state.phone);
		newState.value = e.target.value;
		this.postData.phone = e.target.value;
		this.setState({ phone: newState }, () => {
			this.handlePhoneValidation();
		});
	}

	handleMessageChange = e => {
		let newState = $.extend({}, this.state.message);
		newState.value = e.target.value;
		this.postData.message = e.target.value;
		this.setState({message: newState}, () => {
			this.handleMessageValidation();
		});
	}

	// Handle validation
	handleFirstNameValidation = () => {
		if (this.submitFired) {
			const rules = {
				firstName: 'required'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/contactusformblock/firstnameerror')
			}

			let stateObject = ValidationService.validate(this.state.firstName, rules, customErrorMessages);
			this.setState({firstName: stateObject});
		}
	}

	handleLastNameValidation = () => {
		if (this.submitFired) {
			const rules = {
				lastName: 'required'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/contactusformblock/lastnameerror')
			}

			let stateObject = ValidationService.validate(this.state.lastName, rules, customErrorMessages);
			this.setState({lastName: stateObject});
		}
	}

	handleEmailValidation = () => {
		if (this.submitFired) {
			const rules = {
				email: 'required|email'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/contactusformblock/emailerror'),
				email: TranslationService.getTranslation('/contactusformblock/emailwrongformaterror')
			}

			let stateObject = ValidationService.validate(this.state.email, rules, customErrorMessages);
			this.setState({email: stateObject});
		}
	}

	handlePhoneValidation = () => {
		if (this.submitFired) {
			const rules = {
				phoneNumber: 'required|phoneNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/contactusformblock/phoneerror'),
				phoneNumber: TranslationService.getTranslation('/contactusformblock/phonewrongformaterror')
			}

			let stateObject = ValidationService.validate(this.state.phone, rules, customErrorMessages);
			this.setState({ phone: stateObject });
		}
	}

	handleMessageValidation = () => {
		if (this.submitFired) {
			const rules = {
				message: 'required'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/contactusformblock/messageerror')
			}

			let stateObject = ValidationService.validate(this.state.message, rules, customErrorMessages);
			this.setState({message: stateObject});
		}
	}

	handleSubmit = () => {
		this.submitFired = true;
		this.handleFirstNameValidation();
		this.handleLastNameValidation();
		this.handleEmailValidation();
		this.handlePhoneValidation();
		this.handleMessageValidation();

		if (!this.hasErrorInForm()) {
			ContactUsResource.sendContactUsForm(this.postData).then((response) => {
				this.setState({showThankYouPage: true});
				TrackingService.pushCustomEvent("contactForm");
			}, (error) => {
				let errorObject = {
					value: true
				}
				if (error.errors && !_.isEmpty(error.errors)) {
					errorObject.errorCode = error.errors[0].code;
				}
				this.setState({respondedWithError: errorObject});
			});
		}
	}

	hasErrorInForm() {
		var result = false;
		_.forOwn(this.state, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				result = true;
			}
		});

		return result;
	}

	getErrorCodeForRequest = () => {
		let errorTranslation = TranslationService.getTranslation('/contactusformblock/responseerrorcode' + this.state.respondedWithError.errorCode);
		return !_.isEmpty(errorTranslation) ? errorTranslation : TranslationService.getTranslation('/contactusformblock/responseerror');
	}

	render() {
		return (
			<div className="form">

				{!this.state.showThankYouPage &&
					<div className="row">
						<div className="col-12">
							<h2>{TranslationService.getTranslation('/contactusformblock/heading')}</h2>
							<p>{TranslationService.getTranslation('/contactusformblock/preamble')}</p>
						</div>
						<div className="col-12">
							<Input label={TranslationService.getTranslation('/contactusformblock/firstname')}
									id="firstName"
									value={this.state.firstName.value}
									error={this.state.firstName.error}
									onChange={this.handleFirstNameChange}
									onValidate={this.handleFirstNameValidation}/>
						</div>
						<div className="col-12">
							<Input label={TranslationService.getTranslation('/contactusformblock/lastname')}
									id="lastName"
									value={this.state.lastName.value}
									error={this.state.lastName.error}
									onChange={this.handleLastNameChange}/>
						</div>
						<div className="col-12">
							<Input label={TranslationService.getTranslation('/contactusformblock/email')}
									id="email"
									value={this.state.email.value}
									error={this.state.email.error}
									onChange={this.handleEmailChange}/>
						</div>
						<div className="col-12">
							<Input label={TranslationService.getTranslation('/contactusformblock/phone')}
								id="phone"
								value={this.state.phone.value}
								error={this.state.phone.error}
								onChange={this.handlePhoneChange} />
						</div>
						<div className="col-12">
							<TextArea label={TranslationService.getTranslation('/contactusformblock/message')}
										id="message"
										value={this.state.message.value}
										error={this.state.message.error}
										onChange={this.handleMessageChange}/>
						</div>

						{this.state.respondedWithError.value &&
							<div className="col-12">
								<div className="text-center text-danger font-weight-bold">
									<span>
										{this.getErrorCodeForRequest()}
									</span>
								</div>
							</div>
						}

						<div className="col-12 text-center mt-1">
							<div className="form-group">
								<button type="submit" 
										className="btn btn-primary"
										onClick={this.handleSubmit}>
									{TranslationService.getTranslation('/contactusformblock/button')}
								</button>
							</div>
						</div>
					</div>
				}

				{this.state.showThankYouPage &&
					<div className="row">
						<div className="col-12 contact-us-form-block-thank-you">
							<h2>{TranslationService.getTranslation('/contactusformblock/thankyouheading')}</h2>
							<p>{TranslationService.getTranslation('/contactusformblock/thankyoupreamble')}</p>
						</div>
					</div>
				}

			</div>
		);
	}
}

export default ContactUsComponent;