﻿import moment from "moment/min/moment-with-locales.min.js";

module.exports = {
	createAndPostForm: function(data) {
		var form = document.createElement("form");
		form.method = 'post';
		form.action = data.url;
		for (var key in data.form) {
			if (data.form.hasOwnProperty(key)) {
				var input = document.createElement('input');
				input.type = "text";
				input.name = key;
				input.value = data.form[key];
				form.appendChild(input);
			}
		}
		$(document.body).append(form);
		form.submit();
		return form;
	},

	registerFormToValidator: function(form) {
		form.removeData('validator');
		form.removeData('unobtrusiveValidation');
		$.validator.unobtrusive.parse(form);
	},

	registerExampleToggles: function() {
		$(".btn-example").click(function() {
			var showText = $(this).find(".show");
			var hideText = $(this).find(".hide");
			var hideClass = "d-none";
			if (showText.hasClass(hideClass)) {
				showText.removeClass(hideClass);
				hideText.addClass(hideClass);
			} else {
				hideText.removeClass(hideClass);
				showText.addClass(hideClass);
			}
		});
	},

	initDatepickers: function() {
		$('.datepicker').each(function () {
			var isMobileDevice = BM.functions.isMobileOrTabletDevice();
			var minDate = $(this).find('input').attr('min');
			var maxDate = $(this).find('input').attr('max');
			$(this).find('button').attr('tabindex', '-1');

			var focusOnShow = true;
			if (isMobileDevice) {
				focusOnShow = false;
				$(this).find('input').attr('type', 'date');
			}

			$(this).datetimepicker({
				format: 'YYYY-MM-DD',
				locale: moment().local('sv'),
				minDate: minDate,
				maxDate: maxDate,
				focusOnShow: focusOnShow,
				allowInputToggle: true,
				icons: {
					previous: 'icon icon-angle-left',
					next: 'icon icon-angle-right'
				}
		});
			$(this).on('dp.show', function () {
				$(this).find('.bootstrap-datetimepicker-widget').attr('aria-hidden', 'true');
				$(this).find('button').addClass('active');
				if (isMobileDevice) {
					$(this).find('.bootstrap-datetimepicker-widget').css('display', 'none');
					$(this).find('input[type=date]').focus();
				}
			});
			$(this).on('dp.hide', function () {
				$(this).find('button').removeClass('active');
			});
			$(this).find('input').on('blur', function () {
				$(this).parent().find('button').removeClass('active');
			});
			$(this).on('dp.change', function (e) {
				$(e.target).trigger('input');
			});
		});
	},

	scrollToTop: function() {
		var isMobileDevice = BM.functions.isMobileOrTabletDevice();
		if (isMobileDevice)
			$('html, body').animate({
				scrollTop: $("#form-area").offset().top
			}, 300);
		else
			$('html, body').animate({
				scrollTop: 0
			}, 300);
	}
};