﻿var $ = require("jquery");
import React, { Component } from 'react';

import Input from "../../../shared/components/Input";
import Datepicker from "../../../shared/components/Datepicker";
import RadioGroup from "../../../shared/components/RadioGroup";
import ChoiceService from "../../../shared/services/ChoiceService";
import NeedHelpComponentView from "./views/NeedHelpComponentView";
import TranslationService from "../../../shared/services/TranslationService";
import ValidationService from "../../../shared/services/ValidationService";
import ErrorViewService from "../../../shared/services/ErrorViewService";
import TrackingService from "../../../shared/services/TrackingService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');
let extend = require('lodash/extend');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
	isUndefined: isUndefined,
	extend: extend
};

class SelectDamageTypePage extends Component {

	constructor() {
		super();
		this.state = {
			plateNumber: {
				value: '',
				error: ''
			},
			damageType: {
				value: '',
				error: ''
			},
			damageDate: {
				value: '',
				error: ''
			},
			translations: [],
			damageTypes: []
		};

		this.submitFired = false;
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let newState = {};
		_.extend(newState, this.state, this.props.history.location.state);
		newState.damageTypes = this.populateDamageTypes();
		this.setState(newState);

		TrackingService.pushPageViewEvent("Skadeanmälan",
			"Skadeanmälan - Val av skada",
			"1",
			"Skadeanmälan märkeswebb",
			"Skadeanmälan",
			"Fordon");
	}

	componentDidUpdate() {
		if (typeof BM !== 'undefined') {
			BM.utilities.setExternalLinkIconsOnButtons();
		}
	}

	handlePlateNumberChange = e => {
		let newState = $.extend({}, this.state.plateNumber);
		newState.value = e.target.value.toLocaleUpperCase();
		this.setState({plateNumber: newState}, () => {
			this.handlePlateNumberValidation();
		});
	}

	handleDamageDateChange = value => {
		setTimeout(() => {
			let newState = $.extend({}, this.state.damageDate);
			newState.value = value;
			this.setState({damageDate: newState}, () => {
				this.handleDamageDateValidation();
			});
		}, 10);
	}

	handleDamageTypeChange = e => {
		let newState = $.extend({}, this.state.damageType);
		newState.value = e.target.value;
		this.setState({damageType: newState}, () => {
			this.handleDamageTypeValidation();
		});
	}

	handleDamageDateValidation = () => {
		if (this.submitFired) {
			const rules = {
				damageDate: 'required|customDate'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step1/dateerror'),
				customDate: TranslationService.getTranslation('/reportdamageformpage/step1/datewrongformaterror')
			}
			
			let stateObject = ValidationService.validate(this.state.damageDate, rules, customErrorMessages);
			this.setState({damageDate: stateObject});
		}
	}

	handlePlateNumberValidation = () => {
		if (this.submitFired) {
			const rules = {
				plateNumber: 'required|plateNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step1/platenumbererror'),
				plateNumber: TranslationService.getTranslation('/reportdamageformpage/step1/platenumberwrongformaterror')
			}

			let stateObject = ValidationService.validate(this.state.plateNumber, rules, customErrorMessages);
			this.setState({plateNumber: stateObject});
		}
	}

	handleDamageTypeValidation = () => {
		if (this.submitFired) {
			const rules = {
				damageType: 'required'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step1/whathappenederror'),
			}
			
			let stateObject = ValidationService.validate(this.state.damageType, rules, customErrorMessages);
			this.setState({damageType: stateObject});
		}
	}

	populateDamageTypes = () => {
		return [
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step1/vehicledamage'),
				value: 'vehicleDamage',
				information: TranslationService.getTranslation('/reportdamageformpage/step1/vehicledamageinfo')
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step1/theft'),
				value: 'theft',
				information: TranslationService.getTranslation('/reportdamageformpage/step1/theftinfo')
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step1/mechanicaldamage'),
				value: 'mechanicalDamage',
				information: TranslationService.getTranslation('/reportdamageformpage/step1/mechanicaldamageinfo')
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step1/brokenwindows'),
				value: 'brokenWindows',
				information: TranslationService.getTranslation('/reportdamageformpage/step1/brokenwindowsinfo')
			}
		];
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.submitFired = true;
		this.setState(ValidationService.clearErrorsFromState(this.state), () => {
			this.handlePlateNumberValidation();
			this.handleDamageDateValidation();
			this.handleDamageTypeValidation();

			if (!ValidationService.stateHasError(this.state)) {
				this.populatePostData();

				switch (this.state.damageType.value) {
				case 'vehicleDamage':
					this.props.history.push({
						pathname: '/skada-pa-fordon',
						state: this.state
					});
					break;
				case 'theft':
					this.props.history.push({
						pathname: '/inbrott-och-stold',
						state: this.state
					});
					break;
				case 'mechanicalDamage':
					this.props.history.push({
						pathname: '/maskinskada',
						state: this.state
					});
					break;
				case 'brokenWindows':
					this.props.history.push({
						pathname: '/stenskott-och-trasiga-rutor',
						state: this.state
					});
					break;
				default:
					break;
				}
			} else {
				ErrorViewService.scrollToFirstError();
			}
		});
	}

	populatePostData = () => {
		this.props.postData.selectDamageType = [];

		this.props.postData.selectDamageType.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step1/platenumber'),
			value: this.state.plateNumber.value
		});
		this.props.postData.selectDamageType.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step1/date'),
			value: this.state.damageDate.value
		});
		this.props.postData.selectDamageType.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step1/whathappened'),
			value: ChoiceService.getLabelForValue(this.state.damageTypes, this.state.damageType.value)
		});

		this.props.sharedInfo.damageType = this.state.damageType.value;
	}

	getExternalReportDamageUrl = () => {
		return window.reportDamageFormAppObject.externalReportDamageUrl;
	}

	getPulLink = () => {
		return window.reportDamageFormAppObject.pulLink;
	}

	render() {
		return (
			<div className="report-damage-form-page-container">
				<h1>{TranslationService.getTranslation('/reportdamageformpage/step1/heading')}</h1>
				<p className="lead">{TranslationService.getTranslation('/reportdamageformpage/step1/preamble')}</p>


				<form onSubmit={(event) => {
					this.handleSubmit(event);
				}}>
					<div className="row">
						<div className="col-lg-8">
							<div className="container p-2 bg-white">
								<div className="row">
									<div className="col-12 z-index-1">
										<ul className="progressbar">
											<li className="active">{TranslationService.getTranslation('/reportdamageformpage/step1/progress_text')}</li>
											<li>{TranslationService.getTranslation('/reportdamageformpage/step2/progress_text')}</li>
											<li>{TranslationService.getTranslation('/reportdamageformpage/step3/progress_text')}</li>
											<li>{TranslationService.getTranslation('/reportdamageformpage/step4/progress_text')}</li>
										</ul>
									</div>
								</div>
								
								<hr className="mb-2"/>

								<div className="row">
									<div className="col-md-6">
										<Input label={TranslationService.getTranslation('/reportdamageformpage/step1/platenumber')}
												id="plateNumber"
												value={this.state.plateNumber.value}
												error={this.state.plateNumber.error}
												plateInput={true}
												maxLength={6}
												link={{
													url: this.getPulLink(),
													text: TranslationService.getTranslation('/startpage/barker/userinfo')
												}}
												placeholder={TranslationService.getTranslation('/placeholders/regnr')}
												onChange={this.handlePlateNumberChange}
												onValidate={this.handlePlateNumberValidation}/>
									</div>
									<div className="col-md-6">
										<Datepicker label={TranslationService.getTranslation('/reportdamageformpage/step1/date')}
													id="startDate"
													placeholder={TranslationService.getTranslation('/placeholders/date')}
													minDate={window.reportDamageFormAppObject.minDate}
													maxDate={window.reportDamageFormAppObject.maxDate}
													value={this.state.damageDate.value}
													error={this.state.damageDate.error}
													onChange={this.handleDamageDateChange}
													onValidate={this.handleDamageDateValidation}/>
									</div>
								</div>

								<hr className="mb-2"/>

								<div className="row">
									<div className="col-12 radio-collection">
										<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step1/whathappened')}
													id="damageType"
													value={this.state.damageType.value}
													error={this.state.damageType.error}
													items={this.state.damageTypes}
													onChange={this.handleDamageTypeChange}/>
									</div>
								</div>

								{(this.state.damageType.value === 'theft' || this.state.damageType.value === 'mechanicalDamage') &&
									<div className="row">
										<div className="col-12">
											<div className="report-damage-form-page-info mb-2">
												<p>{TranslationService.getTranslation('/reportdamageformpage/step2/skavitext')}</p>
												<div className="d-flex justify-content-end">
													<a href={this.getExternalReportDamageUrl()} target="_blank" className="btn btn-primary">
														{TranslationService.getTranslation('/reportdamageformpage/step2/skavibutton')}
													</a>
												</div>
											</div>
										</div>
									</div>
								}

								{!(this.state.damageType.value === 'theft' || this.state.damageType.value === 'mechanicalDamage') &&
									<React.Fragment>
										<hr className="mt-1"/>

										<div className="row pt-1">
											<div className="col-12 text-right">
												<button type="submit" className="btn btn-primary">
													{TranslationService.getTranslation('/reportdamageformpage/button/continue')}
												</button>
											</div>
										</div>
									</React.Fragment>
								}
							</div>
						</div>
						<NeedHelpComponentView translations={this.state.translations}/>
					</div>
				</form>
			</div>
		);
	}
}

export default SelectDamageTypePage;