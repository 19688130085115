﻿var $ = require("jquery");
import React, { Component } from 'react';
import utilities from '../../utilities';
import { Redirect } from 'react-router-dom';

import TranslationService from "../../../shared/services/TranslationService";
import TrackingService from "../../../shared/services/TrackingService";

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let extend = require('lodash/extend');

const _ = {
	isEmpty: isEmpty,
	extend: extend
};

class ThankYouPage extends Component {

	constructor() {
		super();
		this.TOYOTA = "Toyota";
		this.LEXUS = "Lexus";
		this.SUZUKI = "Suzuki";

		this.state = {
			translations: []
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let newState = {};
		_.extend(newState, this.state, this.props.history.location.state);
		this.setState(newState);

		TrackingService.pushPageViewEvent("Skadeanmälan",
			"Skadeanmälan - Kvitto",
			"4",
			"Skadeanmälan märkeswebb",
			"Skadeanmälan",
			"Fordon",
			this.props.sharedInfo.trackingName,
			this.props.sharedInfo.trackingExtent);
	}

	componentDidUpdate() {
		utilities.setExternalLinkIconsOnButtons();
	}

	getMapLink = () => {
		return window.reportDamageFormAppObject.mapUrl;
	}

	getWhatHappensNowLink = () => {
		return window.reportDamageFormAppObject.whatHappensNowUrl;
	}

	getBrand = () => {
		return window.reportDamageFormAppObject.brand;
	}

	render() {
		const {sharedInfo} = this.props;
		const mapLink = this.getMapLink();
		const whatHappensNowLink = this.getWhatHappensNowLink();
		const brand = this.getBrand();

		if (_.isEmpty(this.props.sharedInfo.damageType)) {
			return (
				<Redirect to="/"/>
			);
		} else {
			return (
				<div>
					<div className="row mt-3">
						<div className="col-lg-9">
							<div className="container report-damage-form-page-thank-you">
								<div className="row">
									<div className="col-12">
										<h1 className="pt-0 mb-1">
											{TranslationService.getTranslation('/reportdamageformpage/step4/heading')}
										</h1>
										<p className="lead">
											{TranslationService.getTranslation('/reportdamageformpage/step4/preamble')}
										</p>
										
										{sharedInfo.email &&
											<p className="font-weight-bold">
												{TranslationService.getTranslation('/reportdamageformpage/step4/emailconfirmation') + sharedInfo.email}
											</p>
										}

										<div className="report-damage-form-page-info mb-2">
											<h3 className="mb-1">
												{TranslationService.getTranslation('/reportdamageformpage/step4/whattodoheading')}
											</h3>
											<div className="row">
												<div className="col-12">
													<ol>
														<li>
															{TranslationService.getTranslation('/reportdamageformpage/step4/whattodostep1')}
														</li>
														<li>
															{TranslationService.getTranslation('/reportdamageformpage/step4/whattodostep2')}
														</li>
													</ol>
												</div>
											</div>
											<div className="row">
												<div className="col-12">
													{mapLink.length > 0 &&
														<a href={mapLink} target="_blank" className="btn btn-secondary">
															{TranslationService.getTranslation('/reportdamageformpage/step4/whattodobutton' + (brand === this.TOYOTA ? 'toyota' : brand === this.SUZUKI ? 'suzuki' : 'lexus'))}
														</a>
													}
												</div>
											</div>
										</div>

										<div className="report-damage-form-page-info mb-3">
											<h3 className="mb-1">
												{TranslationService.getTranslation('/reportdamageformpage/step4/whatwewilldoheading')}
											</h3>
											<div className="row">
												<div className="col-12">
													<ol>
														<li>
															{TranslationService.getTranslation('/reportdamageformpage/step4/whatwewilldostep1')}
														</li>
														<li>
															{TranslationService.getTranslation('/reportdamageformpage/step4/whatwewilldostep2')}
														</li>
														<li>
															{TranslationService.getTranslation('/reportdamageformpage/step4/whatwewilldostep3')}
														</li>
													</ol>
												</div>
											</div>
											<div className="row">
												<div className="col-12">
													{whatHappensNowLink.length > 0 &&
														<a href={whatHappensNowLink} target="_blank" className="btn btn-secondary">
															{TranslationService.getTranslation('/reportdamageformpage/step4/whatwewilldobutton')}
														</a>
													}
												</div>
											</div>
										</div>

										<p className={brand === this.SUZUKI ? "mb-0" : "h3 mb-0"}>
											{TranslationService.getTranslation('/reportdamageformpage/step4/regards')}
										</p>
										<p className={brand === this.SUZUKI ? "" : "h2"}>
											{TranslationService.getTranslation('/reportdamageformpage/step4/' + (brand === this.TOYOTA ? 'toyota' : brand === this.SUZUKI ? 'suzuki' : 'lexus'))}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default ThankYouPage;