﻿var $ = require("jquery");
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Input from "../../../shared/components/Input";
import RadioGroup from "../../../shared/components/RadioGroup";
import Checkbox from "../../../shared/components/Checkbox";
import TextArea from "../../../shared/components/TextArea";
import Select from "../../../shared/components/Select";
import Datepicker from "../../../shared/components/Datepicker";
import ExampleLink from "../../../shared/components/ExampleLink";
import ContactInput from "../../../shared/components/ContactInput";
import NeedHelpComponentView from "./views/NeedHelpComponentView";
import VehicleWorkingComponentView from "./views/VehicleWorkingComponentView";
import TranslationService from "../../../shared/services/TranslationService";
import ValidationService from "../../../shared/services/ValidationService";
import ChoiceService from "../../../shared/services/ChoiceService";
import ErrorViewService from "../../../shared/services/ErrorViewService";
import TrackingService from "../../../shared/services/TrackingService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');
let extend = require('lodash/extend');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
	extend: extend
};

class TheftPage extends Component {

	constructor(props) {
		super(props);
		this.VEHICLE_STOLEN = "VEHICLE_STOLEN";
		this.VEHICLE_BURGLARY = "VEHICLE_BURGLARY";
		this.ACCESSORIES_STOLEN = "ACCESSORIES_STOLEN";
		this.state = {
			reportingVAT: {value: '',error: ''},
			whoDiscovered: {value: '',error: '', items: []},
			whoDiscoveredInformation: {value: '',error: ''},
			whatHappened: {value: '',error: '',items: []},
			discoveryDescription: {value: '',error: ''},
			parkedDate: {value: '',error: ''},
			parkedLocation: {value: '',error: ''},
			damageDescription: {value: '',error: ''},
			theftDescription: {value: '',error: ''},
			vehicleFound: {value: '',error: ''},
			drivable: {value: '',error: ''},
			vehicleLocked: {value: '',error: ''},
			policeNotified: {value: '',error: ''},
			policeRefNumber: {value: '',error: ''},
			policeRefNumberNotKnown: {value: false, error: ''},
			ssn: {value: '',error: ''},
			phoneNumber: {value: '',error: ''},
			email: {value: '',error: ''},
			confirmEmail: {value: '',error: ''},
			hiredSalvor: {value: '',error: '', items: []},
			vehicleatWorkshop: {value: '', error: '', items: []},
			workshop: {value: '', error: ''},

			translations: []
		};

		this.submitFired = false;
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let newState = {};
		_.extend(newState, this.state, this.props.history.location.state);
		newState.whatHappened.items = this.createWhatHappenedItems(newState.translations);
		newState.whoDiscovered.items = this.createWhoDiscoveredItems(newState.translations);
		newState.vehicleatWorkshop.items = ChoiceService.getBoolChoices(newState.translations);
		newState.hiredSalvor.items = ChoiceService.getBoolChoices(newState.translations);
		this.setState(newState);

		TrackingService.pushPageViewEvent("Skadeanmälan",
			"Skadeanmälan - Uppgifter om skadan",
			"2",
			"Skadeanmälan märkeswebb",
			"Skadeanmälan",
			"Fordon",
			"Inbrott och stöld");
		this.props.sharedInfo.trackingName = "Inbrott och stöld";
	}

	createWhatHappenedItems = (translations) => {
		return [
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/vehiclestolen'),
				value: this.VEHICLE_STOLEN
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/vehicleburglary'),
				value: this.VEHICLE_BURGLARY
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/accessoriesstolen'),
				value: this.ACCESSORIES_STOLEN
			}
		];
	}

	createWhoDiscoveredItems = (translations) => {
		return [
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/myself'),
				value: '1'
			},
			{
				label: TranslationService.getTranslation('/reportdamageformpage/step2/otherperson'),
				value: '2'
			}
		];
	}

	handleReportingVATChange = e => {this.updateState(e.target.value, 'reportingVAT', this.handleReportingVATValidation);}
	handleWhoDiscoveredChange = e => {
		this.updateState('', 'discoveredInformation');
		this.updateState(e.target.value, 'whoDiscovered', this.handleWhoDiscoveredValidation);
	}
	handleWhoDiscoveredInformationChange = e => {this.updateState(e.target.value, 'whoDiscoveredInformation', this.handleWhoDiscoveredInformationValidation);}
	handleWhatHappenedChange = e => {this.updateState(e.target.value, 'whatHappened', this.handleWhatHappenedValidation);}
	handleDiscoveryDescriptionChange = e => {this.updateState(e.target.value, 'discoveryDescription', this.handleDiscoveryDescriptionValidation);}
	handleParkedDateChange = value => {
		setTimeout(() => {
			this.updateState(value, 'parkedDate', this.handleParkedDateValidation);
		}, 10);
	}
	handleParkedLocationChange = e => {this.updateState(e.target.value, 'parkedLocation', this.handleParkedLocationValidation);}
	handleDamageDescriptionChange = e => {this.updateState(e.target.value, 'damageDescription', this.handleDamageDescriptionValidation);}
	handleTheftDescriptionChange = e => {this.updateState(e.target.value, 'theftDescription', this.handleTheftDescriptionValidation);}
	handleVehicleFoundChange = e => {this.updateState(e.target.value, 'vehicleFound', this.handleVehicleFoundValidation);}
	handleDrivableChange = e => {this.updateState(e.target.value, 'drivable', this.handleDrivableValidation);}
	handleHiredSalvorChange = e => { this.updateState(e.target.value, 'hiredSalvor', this.handleHiredSalvorValidation);}
	handleVehicleatWorkshopChange = e => { this.updateState(e.target.value, 'vehicleatWorkshop', this.handleVehicleAtWorkshopValidation);}
	handleWorkshopChange = e => { this.updateState(e.target.value, 'workshop', this.handleWorkshopValidation);}
	handleVehicleLockedChange = e => {this.updateState(e.target.value, 'vehicleLocked', this.handleVehicleLockedValidation);}
	handlePoliceNotifiedChange = e => {this.updateState(e.target.value, 'policeNotified', this.handlePoliceNotifiedValidation);}
	handlePoliceRefNumberChange = e => {this.updateState(e.target.value, 'policeRefNumber', this.handlePoliceRefNumberValidation);}
	handlePoliceRefNumberNotKnownChange = e => {this.updateState(e.target.checked, 'policeRefNumberNotKnown');}
	handleSsnChange = e => {this.updateState(e.target.value, 'ssn', this.handleSsnValidation);}
	handlePhoneNumberChange = e => {this.updateState(e.target.value, 'phoneNumber', this.handlePhoneNumberValidation);}
	handleEmailChange = e => {this.updateState(e.target.value, 'email', this.handleEmailValidation);}
	handleConfirmEmailChange = e => {this.updateState(e.target.value, 'confirmEmail', this.handleEmailValidation);}

	handleParkedDateValidation = () => {
		if (this.submitFired) {
			const rules = {
				parkedDate: 'required|customDate'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/parkeddateerror'),
				customDate: TranslationService.getTranslation('/reportdamageformpage/step2/parkeddatewrongformaterror')
			}

			this.setState({parkedDate: ValidationService.validate(this.state.parkedDate, rules, customErrorMessages)});
		}
	}

	handlePoliceRefNumberValidation = () => {
		if (this.submitFired && !this.state.policeRefNumberNotKnown.value) {
			const rules = {
				policeRefNumber: 'required'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumbererror')
			}

			this.setState({policeRefNumber: ValidationService.validate(this.state.policeRefNumber, rules, customErrorMessages)});
		}
	}

	handleSsnValidation = () => {
		if (this.submitFired) {
			const rules = {
				ssn: 'required|orgSsn|ssn'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/pnrerror'),
				orgSsn: TranslationService.getTranslation('/reportdamageformpage/step2/pnrerror'),
				ssn: TranslationService.getTranslation('/reportdamageformpage/step2/pnrformaterror')
			}

			this.setState({ssn: ValidationService.validate(this.state.ssn, rules, customErrorMessages)});
		}
	}

	handlePhoneNumberValidation = () => {
		if (this.submitFired) {
			const rules = {
				phoneNumber: 'required|phoneNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/phoneerror'),
				phoneNumber: TranslationService.getTranslation('/reportdamageformpage/step2/phoneerror')
			}

			this.setState({phoneNumber: ValidationService.validate(this.state.phoneNumber, rules, customErrorMessages)});
		}
	}

	handleEmailValidation = () => {
		if (this.submitFired) {
			const rules = {
				email: 'required|email',
				confirmEmail: 'required|same:email'
			};

			const customErrorMessages = {
				required: TranslationService.getTranslation('/reportdamageformpage/step2/emailerror'),
				email: TranslationService.getTranslation('/reportdamageformpage/step2/emailformaterror'),
				same: TranslationService.getTranslation('/reportdamageformpage/step2/confirmemailerror')
			};

			const fields = {
				email: this.state.email, 
				confirmEmail: this.state.confirmEmail
			};

			const validatedFields = ValidationService.compareValidate(fields, rules, customErrorMessages);
			this.setState({email: validatedFields['email'], confirmEmail: validatedFields['confirmEmail']});
		}
	}

	handleReportingVATValidation = () => {this.requiredValidation('reportingVAT', '/reportdamageformpage/yesnoerror');}
	handleWhatHappenedValidation = () => {this.requiredValidation('whatHappened', '/reportdamageformpage/step2/whathappenederror');}
	handleDiscoveryDescriptionValidation = () => {this.requiredValidation('discoveryDescription', '/reportdamageformpage/step2/discoverydescriptionerror');}
	handleParkedLocationValidation = () => {this.requiredValidation('parkedLocation', '/reportdamageformpage/step2/parkedwhereerror');}
	handleDamageDescriptionValidation = () => {this.requiredValidation('damageDescription', '/reportdamageformpage/step2/theftdamagedescriptionerror');}
	handleTheftDescriptionValidation = () => {this.requiredValidation('theftDescription', '/reportdamageformpage/step2/theftdescriptionerror');}
	handleVehicleFoundValidation = () => {this.requiredValidation('vehicleFound', '/reportdamageformpage/yesnoerror');}
	handleDrivableValidation = () => {this.requiredValidation('drivable', '/reportdamageformpage/yesnoerror');}
	handleVehicleAtWorkshopValidation = () => { this.requiredValidation('vehicleatWorkshop', '/reportdamageformpage/yesnoerror'); }
	handleHiredSalvorValidation = () => { this.requiredValidation('hiredSalvor', '/reportdamageformpage/yesnoerror'); }
	handleWorkshopValidation = () => { this.requiredValidation('workshop', '/reportdamageformpage/step2/workshoperror'); }
	handleVehicleLockedValidation = () => {this.requiredValidation('vehicleLocked', '/reportdamageformpage/yesnoerror');}
	handlePoliceNotifiedValidation = () => {this.requiredValidation('policeNotified', '/reportdamageformpage/yesnoerror');}
	handleWhoDiscoveredValidation = () => {this.requiredValidation('whoDiscovered', '/reportdamageformpage/step2/whodiscoverederror');}
	handleWhoDiscoveredInformationValidation = () => {this.requiredValidation('whoDiscoveredInformation', '/reportdamageformpage/step2/whodiscoveredotherpersonerror');}

	updateState = (value, property, callback) => {
		let newState = $.extend({}, this.state[property]);
		newState.value = value;

		let object = {};
		object[property] = newState;

		this.setState(object, () => {
			if (callback) {
				callback();
			}
		});
	}

	requiredValidation = (stateField, translationKey) => {
		if (this.submitFired) {
			let rules = {};
			rules[stateField] = 'required';

			let customErrorMessages = {
				required: TranslationService.getTranslation(translationKey)
			};

			let state = {};
			state[stateField] = ValidationService.validate(this.state[stateField], rules, customErrorMessages);
			this.setState(state);
		}
	}

	handleGoBack = () => {
		this.populatePostData();

		var stateWithoutErrors = this.state;
		_.forOwn(stateWithoutErrors, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				stateObject.error = "";
			}
		});

		this.props.history.push({
			pathname: '/val-av-skada',
			state: stateWithoutErrors
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.submitFired = true;
		this.setState(ValidationService.clearErrorsFromState(this.state), () => {
				this.handleReportingVATValidation();
				this.handleWhatHappenedValidation();
				this.handleDiscoveryDescriptionValidation();
				this.handlePoliceNotifiedValidation();
				this.handleSsnValidation();
				this.handlePhoneNumberValidation();
				this.handleEmailValidation();
				this.handleWhoDiscoveredValidation();

				if (!_.isEmpty(this.state.whoDiscovered.value) && this.state.whoDiscovered.value === '2') {
					this.handleWhoDiscoveredInformationValidation();
				}

				if (this.state.whatHappened.value === this.ACCESSORIES_STOLEN) {
					this.handleTheftDescriptionValidation();
				}

				if (this.state.whatHappened.value === this.VEHICLE_STOLEN ||
					this.state.whatHappened.value === this.VEHICLE_BURGLARY) {
					this.handleParkedLocationValidation();
					this.handleParkedDateValidation();
				}

				if (this.state.whatHappened.value === this.VEHICLE_STOLEN) {
					this.handleVehicleFoundValidation();

					if (this.state.vehicleFound.value === "1") {
						this.handleDrivableValidation();
						this.handleHiredSalvorValidation();
						this.handleVehicleAtWorkshopValidation();

						if (!_.isEmpty(this.state.vehicleatWorkshop.value) && !!+this.state.vehicleatWorkshop.value) {
							this.handleWorkshopValidation();
						}
					}
				}

				if ((this.state.whatHappened.value === this.VEHICLE_BURGLARY) ||
					(this.state.whatHappened.value === this.VEHICLE_STOLEN && this.state.vehicleFound.value === "1")) {
					this.handleDamageDescriptionValidation();
				}

				if (this.state.whatHappened.value === this.VEHICLE_STOLEN ||
					this.state.whatHappened.value === this.VEHICLE_BURGLARY) {
					this.handleVehicleLockedValidation();
				}

				if (this.state.policeNotified.value === "1") {
					this.handlePoliceRefNumberValidation();
				}

				if (!ValidationService.stateHasError(this.state)) {
					this.populatePostData();

					this.props.history.push({
						pathname: '/godkann',
						state: this.state
					});
				} else {
					ErrorViewService.scrollToFirstError();
				}
			});
	}

	populatePostData = () => {
		var boolChoices = ChoiceService.getBoolChoices(this.state.translations);
		this.props.postData.damageInformation = [];
		this.props.postData.contactInformation = [];

		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/companyreportsvat'),
			value: ChoiceService.getLabelForValue(boolChoices, this.state.reportingVAT.value)
		});

		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/whodiscoveredtheft'),
			value: this.state.whoDiscovered.value === '1' ? ChoiceService.getLabelForValue(this.createWhoDiscoveredItems(this.state.translations), this.state.whoDiscovered.value) : this.state.whoDiscoveredInformation.value
		});

		var theftType = ChoiceService.getLabelForValue(this.state.whatHappened.items, this.state.whatHappened.value);
		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/thefttype'),
			value: theftType
		});
		this.props.sharedInfo.trackingExtent = theftType;

		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/discoverydescription'),
			value: this.state.discoveryDescription.value
		});

		if (this.state.whatHappened.value === this.VEHICLE_STOLEN ||
			this.state.whatHappened.value === this.VEHICLE_BURGLARY) {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/parkedwhere'),
				value: this.state.parkedLocation.value
			});
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/parkeddate'),
				value: this.state.parkedDate.value
			});
		}

		if ((this.state.whatHappened.value === this.VEHICLE_BURGLARY) ||
			(this.state.whatHappened.value === this.VEHICLE_STOLEN && this.state.vehicleFound.value === "1")) {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation(this.state.translations,
					'/reportdamageformpage/step2/theftdamagedescription'),
				value: this.state.damageDescription.value
			});
		}

		if (this.state.whatHappened.value === this.ACCESSORIES_STOLEN) {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/theftdescription'),
				value: this.state.theftDescription.value
			});
		}

		if (this.state.whatHappened.value === this.VEHICLE_STOLEN) {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/vehiclefound'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.vehicleFound.value)
			});

			if (this.state.vehicleFound.value === "1") {
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/drivable'),
					value: ChoiceService.getLabelForValue(boolChoices, this.state.drivable.value)
				});

				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/hiredsalvor'),
					value: ChoiceService.getLabelForValue(boolChoices, this.state.hiredSalvor.value)
				});
				this.props.postData.damageInformation.push({
					label: TranslationService.getTranslation('/reportdamageformpage/step2/vehicleatworkshop'),
					value: ChoiceService.getLabelForValue(boolChoices, this.state.vehicleatWorkshop.value)
				});
				if (this.state.vehicleatWorkshop.value === '1') {
					this.props.postData.damageInformation.push({
						label: TranslationService.getTranslation('/reportdamageformpage/step2/workshop'),
						value: this.state.workshop.value
					});
				}
			}
		}

		if (this.state.whatHappened.value === this.VEHICLE_STOLEN ||
			this.state.whatHappened.value === this.VEHICLE_BURGLARY) {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/vehiclelocked'),
				value: ChoiceService.getLabelForValue(boolChoices, this.state.vehicleLocked.value)
			});
		}

		this.props.postData.damageInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/policenotified'),
			value: ChoiceService.getLabelForValue(boolChoices, this.state.policeNotified.value)
		});

		if (this.state.policeNotified.value === "1") {
			this.props.postData.damageInformation.push({
				label: TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumber'),
				value: this.state.policeRefNumberNotKnown.value === true
					? TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumbernotknown')
					: this.state.policeRefNumber.value
			});
		}

		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/pnr'),
			value: this.state.ssn.value
		});
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/phone'),
			value: this.state.phoneNumber.value
		});
		this.props.postData.contactInformation.push({
			label: TranslationService.getTranslation('/reportdamageformpage/step2/email'),
			value: this.state.email.value
		});

		this.props.sharedInfo.email = this.state.email.value;
	}

	getPolicePresentInformationText = () => {
		if (this.state.policeNotified.value === '0') {
			return TranslationService.getTranslation('/reportdamageformpage/step2/policepresentinfo');
		} else {
			return '';
		}
	}

	render() {
		if (_.isEmpty(this.props.sharedInfo.damageType)) {
			return (
				<Redirect to="/"/>
			);
		} else {
			return (
				<div className="report-damage-form-page-container">
					<h1>{TranslationService.getTranslation('/reportdamageformpage/step1/theft')}</h1>
					<p className="lead">{TranslationService.getTranslation('/reportdamageformpage/step2/preamble')}</p>

					<form onSubmit={(event) => {
						this.handleSubmit(event);
						}}>
						<div className="row">
							<div className="col-lg-8">
								<div className="container p-2 bg-white">
									<div className="row">
										<div className="col-12 z-index-1">
											<ul className="progressbar">
												<li className="success">{TranslationService.getTranslation('/reportdamageformpage/step1/progress_text')}</li>
												<li className="active">{TranslationService.getTranslation('/reportdamageformpage/step2/progress_text')}</li>
												<li>{TranslationService.getTranslation('/reportdamageformpage/step3/progress_text')}</li>
												<li>{TranslationService.getTranslation('/reportdamageformpage/step4/progress_text')}</li>
											</ul>
										</div>
									</div>

									<hr className="mb-2"/>

									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/companyreportsvat')}
														id="companyreport"
														value={this.state.reportingVAT.value}
														error={this.state.reportingVAT.error}
														inline={true}
														items={ChoiceService.getBoolChoices(this.state.translations)}
														onChange={this.handleReportingVATChange}/>
										</div>
									</div>

									<div className="row">
										<div className="col-12 col-md-6">
											<Select label={TranslationService.getTranslation('/reportdamageformpage/step2/whodiscoveredtheft')}
													id="whoDiscovered"
											        value={this.state.whoDiscovered.value}
											        error={this.state.whoDiscovered.error}
											        items={this.state.whoDiscovered.items}
											        onChange={this.handleWhoDiscoveredChange}/>
										</div>

										{(!_.isEmpty(this.state.whoDiscovered.value) && this.state.whoDiscovered.value === '2') && 
											<div className="col-12">
												<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/whodiscoveredotherperson')}
														id="whoDiscoveredInformation"
												       value={this.state.whoDiscoveredInformation.value}
												       error={this.state.whoDiscoveredInformation.error}
												       onChange={this.handleWhoDiscoveredInformationChange}
												       onValidate={this.handleWhoDiscoveredInformationValidation}/>
											</div>
										}
									</div>

									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/thefttype')}
														id="whathappened"
														value={this.state.whatHappened.value}
														error={this.state.whatHappened.error}
														inline={false}
														items={this.state.whatHappened.items}
														onChange={this.handleWhatHappenedChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<TextArea   label={TranslationService.getTranslation('/reportdamageformpage/step2/discoverydescription')}
														id="discoveryDescription"
														value={this.state.discoveryDescription.value}
														error={this.state.discoveryDescription.error}
														onChange={this.handleDiscoveryDescriptionChange}
														removeBottomPadding={true}/>
											<ExampleLink id="discoveryDescriptionExample" showText="Visa exempel" hiddenText="Dölj exempel">
												<div className="mt-05 p-05 bg-light">
													<p className="text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/discoverydescriptionexample1')}</p>
												</div>
											</ExampleLink>
										</div>
									</div>
									{this.state.whatHappened.value === this.ACCESSORIES_STOLEN &&
										<div className="row">
											<div className="col-12">
												<TextArea   label={TranslationService.getTranslation('/reportdamageformpage/step2/theftdescription')}
															id="theftDescription"
															value={this.state.theftDescription.value}
															error={this.state.theftDescription.error}
															onChange={this.handleTheftDescriptionChange}
															removeBottomPadding={true}/>
												<ExampleLink id="theftDescriptionExample" showText="Visa exempel" hiddenText="Dölj exempel">
													<div className="mt-05 p-05 bg-light">
														<p className="text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/theftdescriptionexample1')}</p>
													</div>
												</ExampleLink>
											</div>
										</div>
									}
									{(this.state.whatHappened.value === this.VEHICLE_STOLEN || this.state.whatHappened.value === this.VEHICLE_BURGLARY) &&
										<div className="row">
											<div className="col-md-6">
												<Datepicker label={TranslationService.getTranslation('/reportdamageformpage/step2/parkeddate')}
															id="parkedDate"
															placeholder={TranslationService.getTranslation('/placeholders/date')}
															minDate={window.reportDamageFormAppObject.minDate}
															maxDate={window.reportDamageFormAppObject.maxDate}
															value={this.state.parkedDate.value}
															error={this.state.parkedDate.error}
															onChange={this.handleParkedDateChange}
															onValidate={this.handleParkedDateValidation}/>
											</div>
										</div>
									}
									{(this.state.whatHappened.value === this.VEHICLE_STOLEN || this.state.whatHappened.value === this.VEHICLE_BURGLARY) &&
										<div className="row">
											<div className="col-12">
												<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/parkedwhere')}
														id="parkedLocation"
														value={this.state.parkedLocation.value}
														error={this.state.parkedLocation.error}
														information={TranslationService.getTranslation('/reportdamageformpage/step2/parkedwhereinformation')}
														onChange={this.handleParkedLocationChange}/>
											</div>
										</div>
									}
									{this.state.whatHappened.value === this.VEHICLE_STOLEN &&
										<div className="row">
											<div className="col-12">
												<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/vehiclefound')}
															id="vehicleFound"
															value={this.state.vehicleFound.value}
															error={this.state.vehicleFound.error}
															inline={true}
															items={ChoiceService.getBoolChoices(this.state.translations)}
															onChange={this.handleVehicleFoundChange}/>
											</div>
										</div>
									}
									{((this.state.whatHappened.value === this.VEHICLE_BURGLARY)
										|| (this.state.whatHappened.value === this.VEHICLE_STOLEN && this.state.vehicleFound.value === "1")) &&
										<div className="row">
											<div className="col-12">
												<TextArea   label={TranslationService.getTranslation('/reportdamageformpage/step2/theftdamagedescription')}
															id="damageDescription"
															value={this.state.damageDescription.value}
															error={this.state.damageDescription.error}
															onChange={this.handleDamageDescriptionChange}
															removeBottomPadding={true}/>
												<ExampleLink id="damageDescriptionExample" showText="Visa exempel" hiddenText="Dölj exempel">
													<div className="mt-05 p-05 bg-light">
														<p className="text-xs">{TranslationService.getTranslation('/reportdamageformpage/step2/theftdamagedescriptionexample1')}</p>
													</div>
												</ExampleLink>
											</div>
										</div>
									}
									{(this.state.whatHappened.value === this.VEHICLE_STOLEN && this.state.vehicleFound.value === "1") &&
										<div className="row">
											<div className="col-12">
												<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/drivable')}
															id="drivable"
															value={this.state.drivable.value}
															error={this.state.drivable.error}
															information={TranslationService.getTranslation('/reportdamageformpage/step2/drivableinformation')}
															inline={true}
															items={ChoiceService.getBoolChoices(this.state.translations)}
															onChange={this.handleDrivableChange}/>
											</div>
										</div>
									}
									{(this.state.whatHappened.value === this.VEHICLE_STOLEN && this.state.vehicleFound.value === "1") &&
										<VehicleWorkingComponentView translations={this.state.translations}
																	 hiredSalvorObject={this.state.hiredSalvor}
																	 vehicleatWorkshopObject={this.state.vehicleatWorkshop}
																	 workshopObject={this.state.workshop}
																	 handleHiredSalvorChange={this.handleHiredSalvorChange}
																	 handleVehicleatWorkshopChange={this.handleVehicleatWorkshopChange}
																	 handleWorkshopChange={this.handleWorkshopChange}
																	 handleWorkshopValidation={this.handleWorkshopValidation}/>
									}
									{(this.state.whatHappened.value === this.VEHICLE_STOLEN || this.state.whatHappened.value === this.VEHICLE_BURGLARY) &&
										<div className="row">
											<div className="col-12">
												<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/vehiclelocked')}
															id="vehicleLocked"
															value={this.state.vehicleLocked.value}
															error={this.state.vehicleLocked.error}
															inline={true}
															items={ChoiceService.getBoolChoices(this.state.translations)}
															onChange={this.handleVehicleLockedChange}/>
											</div>
										</div>
									}
									<div className="row">
										<div className="col-12">
											<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/policenotified')}
														id="policeNotified"
														value={this.state.policeNotified.value}
														error={this.state.policeNotified.error}
														information={this.getPolicePresentInformationText()}
														inline={true}
														items={ChoiceService.getBoolChoices(this.state.translations)}
														onChange={this.handlePoliceNotifiedChange}/>
										</div>
									</div>
									{this.state.policeNotified.value === "1" &&
										<div className="row">
											<div className="col-sm-9">
												<Input  label={TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumber')}
														id="policeRefNumber"
														formGroupClass={'mb-1-xs-down'}
														value={this.state.policeRefNumber.value}
														error={this.state.policeRefNumber.error}
														onChange={this.handlePoliceRefNumberChange}/>
											</div>
											<div className="col-sm-3 d-flex align-items-center">
												<Checkbox   text={TranslationService.getTranslation('/reportdamageformpage/step2/policerefnumbernotknown')}
															id="policeRefNumberNotKnown"
															value={this.state.policeRefNumberNotKnown.value}
															error={this.state.policeRefNumberNotKnown.error}
															onChange={this.handlePoliceRefNumberNotKnownChange}/>
											</div>
										</div>
									}

									<hr/>

									<ContactInput   translations={this.state.translations}
													ssn={this.state.ssn}
													phoneNumber={this.state.phoneNumber}
													email={this.state.email}
													confirmEmail={this.state.confirmEmail}
													onSsnChange={this.handleSsnChange}
													onPhoneNumberChange={this.handlePhoneNumberChange}
													onEmailChange={this.handleEmailChange}
													onConfirmEmailChange={this.handleConfirmEmailChange}
													onConfirmEmailValidation={this.handleEmailValidation}
													onPhoneNumberValidation={this.handlePhoneNumberValidation}
													onSsnValidation={this.handleSsnValidation}
													onEmailValidation={this.handleEmailValidation}/>

									<hr/>

									<div className="row pt-2">
										<div className="col-sm-6 mb-1 text-left">
											<button type="button" className="btn btn-light btn-cancel" onClick={this.handleGoBack}>
												{TranslationService.getTranslation('/reportdamageformpage/button/back')}
											</button>
										</div>
										<div className="col-sm-6 text-right">
											<button type="submit" className="btn btn-primary">
												{TranslationService.getTranslation('/reportdamageformpage/button/continue')}
											</button>
										</div>
									</div>
								</div>
							</div>
							<NeedHelpComponentView translations={this.state.translations}/>
						</div>
					</form>
				</div>
			);
		}
	}
}

export default TheftPage;