﻿class TrackingService {
	pushPageViewEvent = (
		type,
		title,
		step,
		service,
		grouping,
		productType,
		name,
		extent
	) => {
		window.dataLayer.push({
			event: "Page View Event",
			pageInfo: {
				type: type,
				title: title,
				location:
					window.location.host +
					window.location.pathname +
					window.location.hash,
				pathName: window.location.pathname,
				step: step,
				service: service,
				product: {
					grouping: grouping,
					type: productType,
					name: name,
					extent: extent,
				},
			},
		});
	};

	pushNewPageViewEvent = (category, title, step, service) => {
		let env = "production";
		if (window.location.href.indexOf("wwwsystest || wwwacctest") > -1) {
			env = "test";
		}

		window.dataLayer.push({
			event: "Page View Event",
			pageInfo: {
				companyName: "Länsförsäkringar Stockholm",
				companyNumber: "28",
				customerSegment: "Privat",
				environment: env,
				loggedIn: "0",
				pathName: window.location.pathname,
				service: service,
				step: step,
				title: title,
				productInfo: {
					category: category,
					closureType: "Lead",
					group: "Motor",
					payment: undefined,
					period: undefined,
				},
			},
		});
	};

	pushCustomEvent = (eventName) => {
		window.dataLayer.push({
			event: eventName,
		});
	};
}

export default new TrackingService();
