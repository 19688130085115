﻿import Validator from 'validatorjs';

// Lodash functions
let isUndefined = require('lodash/isUndefined');
let isObject = require('lodash/isObject');
let isEmpty = require('lodash/isEmpty');
let forOwn = require('lodash/forOwn');

const _ = {
	isUndefined: isUndefined,
	isObject: isObject,
	isEmpty: isEmpty,
	forOwn: forOwn
};

Validator.register('ssn', function(input, requirement, attribute) { // requirement parameter defaults to null

	// Check valid length & form
	if (!input) { return false; }

	if (input.indexOf('-') === -1) {
		if (input.length === 10) {
			input = input.slice(0, 6) + "-" + input.slice(6);
		} else {
			input = input.slice(0, 8) + "-" + input.slice(8);
		}
	}
	if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) { return false };

	// Clean input
	input = input.replace('-', '');
	if (input.length === 12) {
		input = input.substring(2);
	}

	let month = input.slice(2, 4);
	if (parseInt(month) > 12) {
		return false;
	}

	// Declare variables
	var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5), (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6)-1), ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
		sum = 0,
		numdigits = input.length,
		parity = numdigits % 2,
		i,
		digit;

	// Check valid date
	if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;

	// Check luhn algorithm
	for (i = 0; i < numdigits; i = i + 1) {
		digit = parseInt(input.charAt(i), 10);
		if (i % 2 === parity) { digit *= 2; }
		if (digit > 9) { digit -= 9; }
		sum += digit;
	}
	return (sum % 10) === 0;

}, 'The social security number is not in the format yyyymmdd-xxxx.');

Validator.register('accept', function(input, requirement, attribute) { // requirement parameter defaults to null

	if (!input) { return false; }

	if (input == "on") {return true }


}, 'The accept button is not clicked.');

Validator.register('orgSsn', function(value, requirement, attribute) {

	let luhn = function(number) {
		number = number.replace('-', '').replace('+', '');

		var pattern = /^\d{10}$|^\d{12}$/;

		if (!pattern.test(number))
			return false;

		number = number.length === 12 ? number.substr(2) : number;

		if (number.length < 1)
			return false;

		var sum = 0;
		var index = 0;
		var substring = number.substring(0, number.length - 1);
		for (var i = 0; i < substring.length; i++) {
			var val = substring[i];
			var multiplier = index % 2 === 0 ? 2 : 1;
			var intValue = val * multiplier;

			sum += intValue > 9 ? ((intValue + "")[0] * 1) + ((intValue + "")[1] * 1) : intValue;

			index++;
		}

		var controlNumber = 10 - (sum % 10);

		if (controlNumber === 10)
			controlNumber = 0;

		return number[number.length - 1] == controlNumber;
	};

	let p = value.match(/^(((\d{1})(\d{7})\-(\d{4})$))|(((\d{1})(\d{5})\-(\d{4})$))|(\d{12})|(\d{10})$/);
	if (!p) {
		return false;
	}

	return luhn(value);
}, 'The ssn/org is not in the format');

Validator.register('customDate', function(value, requirement, attribute) {
	return value.match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/);
}, 'The date is not in the format');

Validator.register('plateNumber', function(value, requirement, attribute) {
	return value.match(/^[a-zA-Z]{3}\d{2}([a-zA-Z]|\d{1})$/);
}, 'The plate number is not in the format');

Validator.register('email', function(value, requirement, attribute) {
	return value.match(/^$|^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
}, 'The email is not in the format');

Validator.register('phoneNumber', function(value, requirement, attribute) {
	var phoneNumber = value;
	if (phoneNumber.length < 1)
		return true;
	phoneNumber = phoneNumber.replace(/\s/g, '');

	var plusFirstIndex = phoneNumber.indexOf('+');
	var plusLastIndex = phoneNumber.lastIndexOf('+');
	if (plusFirstIndex !== plusLastIndex || plusFirstIndex > 0)
		return false;
	phoneNumber = phoneNumber.replace('+', '');

	var firstIndex = phoneNumber.indexOf('-');
	var lastIndex = phoneNumber.lastIndexOf('-');

	if (firstIndex !== lastIndex ||
		firstIndex === 0 ||
		lastIndex === phoneNumber.length - 1) {
		return false;
	}
	phoneNumber = phoneNumber.replace('-', '');

	if (phoneNumber.length < 5 || phoneNumber.length > 15 || !/^\d+$/.test(phoneNumber))
		return false;
	return true;
}, 'The phone number is not in the format');

class ValidationService {

	validate = (fieldObject, rules, customErrorMessages) => {
		var keyNames = Object.keys(rules);

		// Build value object
		let value = {};
		value[keyNames[0]] = fieldObject.value;

		let validator =  new Validator(
			value,
			rules,
			customErrorMessages
		);

		if (validator.fails()) {
			fieldObject.error = validator.errors.first(keyNames[0]);
		} else {
			fieldObject.error = '';
		}
		return fieldObject;
	};

	compareValidate = (fields, rules, customErrorMessages) => {
		let values = {};

		for (let key in fields) {
			fields[key].error = '';
			if (fields.hasOwnProperty(key)) {
				values[key] = fields[key].value;
			}
		}

		let validator =  new Validator(
			values,
			rules,
			customErrorMessages
		);

		if (validator.fails()) {
			for (let error in validator.errors.errors) {
				if (validator.errors.errors.hasOwnProperty(error)) {
					fields[error].error = validator.errors.first(error);
				}
			}
		} else {
			for (let field in fields) {
				if (fields.hasOwnProperty(field)) {
					fields[field].error = '';
				}
			}
		}

		return fields;
	};

	stateHasError = (state) => {
		var result = false;
		_.forOwn(state, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				result = true;
			}
		});
		return result;
	}

	clearErrorsFromState = (state) => {
		_.forOwn(state, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				stateObject.error = '';
			}
		});

		return state;
	}

}

export default new ValidationService();