﻿var Promise = require("bluebird");

// Lodash functions
let isEmpty = require('lodash/isEmpty');

const _ = {
	isEmpty: isEmpty
};

class CarriageWarrantyResource {

	getDamageWarrantyStatus = (regNr) => {
		return new Promise(function(resolve, reject) {
			$.ajax({
				type: "GET",
				headers: {
					"Authorization": 'Atmosphere atmosphere_app_id="' + window.siteConfigObject.lfAppId + '"'
				},
				url: window.apiReferences.getDamageWarrantyStatusUrl + "?brand=" + window.siteConfigObject.currentBrand + "&regNr=" + regNr,
				contentType: "application/json; charset=UTF-8"
			}).success((response) => {
				if (!_.isEmpty(response.errors)) {
					reject(response);
				} else {
					resolve(response);
				}
			}).error((errorResponse) => {
				reject(errorResponse);
			});
		});
	}
}

export default new CarriageWarrantyResource();