﻿module.exports = {
	initStep: function () {
		var form = $("#form-area").find("form");
		BM.forms.registerFormToValidator(form);
		BM.forms.scrollToTop();
		BM.forms.registerExampleToggles();
		BM.forms.initDatepickers();
		
		if ($("input[name='back']").length) {
			$(".btn-cancel").click(function() {
				$(".cancel").first().click();
			});
		}
	}
};