﻿import React, { Component } from 'react'

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined,
};

const defaultProps = {
	label: 'label_text',
	id: '',
	items: [],
	informationText: '',
	error: '',
	value: {}
};

class CustomSelect extends Component {
	constructor () {
		super();
		this.text = '';
	}

	onChange = (e) => {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	};

	render () {
		const {id, label, items, informationText, error, value} = this.props;

		return (
			<div className={'form-group ' + (!_.isUndefined(error) && !_.isEmpty(error) ? 'has-danger ' : '')}>
				<label htmlFor={id}>{label}</label>
				<div className="input-group input-group-select">
					<div className="styled-select">
						<select className="form-control" id={id} onChange={this.onChange}>
							<option value="">Välj</option>
							{items.map((item, key) =>
									<option value={item.value} key={item.value + key}>{item.label}</option>
								)
							}
						</select>
					</div>
				</div>
				<small className="field-validation-error">
					{error}
				</small>
				<small className="text-muted form-text">{informationText}</small>
			</div>
		)
	}
}

CustomSelect.defaultProps = defaultProps;

export default CustomSelect;