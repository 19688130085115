﻿var jQuery = require("jquery");
import React from "react";
import "eonasdan-bootstrap-datetimepicker";

// Lodash functions
let isEmpty = require("lodash/isEmpty");
let isUndefined = require("lodash/isUndefined");

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined,
};

const defaultProps = {
	label: "label_text",
	id: "",
	placeholder: "",
};

class Datepicker extends React.Component {
	constructor() {
		super();
		this.dirty = false;
	}

	componentDidMount() {
		const { id } = this.props;
		var $ = jQuery.noConflict();

		jQuery.browser = {};
		(function () {
			jQuery.browser.msie = false;
			jQuery.browser.version = 0;
			if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
				jQuery.browser.msie = true;
				jQuery.browser.version = RegExp.$1;
			}
		})();

		$("#" + id).datetimepicker({
			format: "YYYY-MM-DD",
			minDate: this.props.minDate,
			maxDate: this.props.maxDate,
			allowInputToggle: true,
			icons: {
				previous: "icon icon-chevron-left",
				next: "icon icon-chevron-right",
			},
		});

		$("#" + id).on("dp.show", function () {
			$("#" + id + " button").addClass("active");
			$("#" + id + " .bootstrap-datetimepicker-widget").attr(
				"aria-hidden",
				"true"
			);
		});

		$("#" + id).on(
			"dp.hide",
			function (e) {
				$(`#` + id + " button").removeClass("active");
				let date = e.date.format("YYYY-MM-DD");
				this.props.onChange(date);
			}.bind(this)
		);

		$("#" + id).on(
			"dp.change",
			function (e) {
				if (e.date) {
					let date = e.date.format("YYYY-MM-DD");
					this.props.onChange(date);
				} else {
					this.props.onChange("");
				}
			}.bind(this)
		);
	}

	onBlur = (e) => {
		this.dirty = true;
		if (this.props.onValidate) {
			this.props.onValidate(e);
		}
	};

	updateState = (e) => {
		this.dirty = true;
		this.props.onChange(e.target.value, e);
	};

	_shouldAddValidClass = () => {
		const { value, error } = this.props;
		return (
			(this.dirty && _.isUndefined(value)) ||
			(!_.isEmpty(value) && (_.isUndefined(error) || _.isEmpty(error)))
		);
	};

	render() {
		const { label, id, placeholder, error, value } = this.props;

		return (
			<div
				className={
					"form-group " +
					(!_.isUndefined(error) && !_.isEmpty(error)
						? " has-danger"
						: "")
				}
			>
				<label htmlFor={id}>{label}</label>
				<div
					className="input-group datepicker custom-white-datepicker"
					id={id}
				>
					<input
						type="text"
						id={id + `input`}
						className="form-control"
						value={value}
						placeholder={placeholder}
						onChange={this.updateState.bind(this)}
					/>
					<span className="input-group-addon input-group-append">
						<button className="btn datepicker-button" type="button" tabIndex="-1">
							<p className="datepicker-icon"></p>
						</button>
					</span>
				</div>
				<span className="field-validation-error">{error}</span>
			</div>
		);
	}
}

Datepicker.defaultProps = defaultProps;

export default Datepicker;
