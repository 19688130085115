﻿module.exports = {
	create: function (name, value, hours, domain) {
		var expires = '',
			setDomain = '';
		if (hours) {
			var date = new Date();
			date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
			expires = '; expires=' + date.toGMTString();
		}
		else {
			expires = '';
		}
		if (domain) {
			setDomain = '; domain=' + domain;
		}
		document.cookie = name + '=' + value + expires + setDomain + '; path=/';
	},
	read: function (name) {
		var nameEQ = name + '=',
			ca = document.cookie.split(';');

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ')
				c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0)
				return c.substring(nameEQ.length, c.length);
		}
		return null;
	},
	erase: function (name, domain) {
		if (domain) {
			this.create(name, '', -1, domain);
		}
		else {
			this.create(name, '', -1);
		}
	}
};