﻿var $ = require("jquery");

module.exports = {
	setExternalLinkIconsOnButtons: function () {
		let hostname = window.location.hostname;

		$("a.btn").each(function() {
			let href = $(this).attr("href");

			if (href.length > 0 && href[0] !== "/") {
				if (href.indexOf(hostname) === -1) {
					$(this).addClass("btn-icon").addClass("btn-icon-external");
					$(this).attr("target", "_blank");
				}
			}
		});
	},

	setExternalLinkIconsOnLinks: function () {
		let hostname = window.location.hostname;

		$("a:not(.btn)").each(function() {
			let href = $(this).attr("href");

			if (!$(this).children().length &&
				href.indexOf("tel:") === -1 &&
				href.indexOf("mailto:") === -1 &&
				href.length > 0 &&
				href[0] !== "/") {
				if (href.indexOf(hostname) === -1) {
					$(this).addClass("link-icon").addClass("link-icon-external");
				}
			}
		});
	},
	init: function() {
		if (BM.cookieHandler.read('kaka_ok') !== 'true') {
			$('.cookie-information').removeClass('fadeOut');
			$('.cookie-information').addClass('fadeIn');
		} else {
			$('.cookie-information').removeClass('fadeIn');
			$('.cookie-information').addClass('fadeOut');
		}

		$('.cookie-ok-button').click(function () {
			BM.cookieHandler.create('kaka_ok', 'true', 24 * 365 * 10);
			$('.cookie-information').removeClass('fadeIn');
			$('.cookie-information').addClass('fadeOut');
		});
	}
};