﻿import React, { Component } from 'react';

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined
};

class TextArea extends Component {

	constructor() {
		super();
		this.dirty = false;
	}

	onChange = (e) => {
		this.dirty = true;
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	};

	onBlur = (e) => {
		if (this.props.onValidate) {
			this.props.onValidate(e);
		}
	};

	_shouldAddValidClass = () => {
		const {value, error} = this.props;
		return (this.dirty && _.isUndefined(value)) || (!_.isEmpty(value) && (_.isUndefined(error) || _.isEmpty(error)));
	};

	render() {
		const {label, placeholder, value, id, error, removeBottomPadding, isDisabled} = this.props;

		return (

			<div className={'form-group ' + (removeBottomPadding ? 'mb-0' : '') + 
				(!_.isUndefined(error) && !_.isEmpty(error) ? ' has-danger' : '')}>

				<label htmlFor={id}>{label}</label>
				<div className="input-field">
					<textarea className="form-control" 
								cols="20"
								id={id}
								placeholder={placeholder}
								onChange={this.onChange}
								onBlur={this.onBlur}
								value={value}
								rows="2"/>
				</div>
				<span className="field-validation-error">
					<span>
						{error}
					</span>
				</span>
			</div>
		);
	}
}

export default TextArea;