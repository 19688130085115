﻿import React, { Component } from 'react';

import Input from "../../../../shared/components/Input";
import RadioGroup from "../../../../shared/components/RadioGroup";
import TranslationService from "../../../../shared/services/TranslationService";

// Lodash functions
let isEmpty = require('lodash/isEmpty');

const _ = {
	isEmpty: isEmpty
};

class VehicleWorkingComponentView extends Component {

	render() {
		const {hiredSalvorObject, vehicleatWorkshopObject, workshopObject, handleHiredSalvorChange, handleVehicleatWorkshopChange, handleWorkshopChange, handleWorkshopValidation} = this.props;

		return (
			<div>
				<div className="row">
					<div className="col-12">
						<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/hiredsalvor')}
						            id="hiredSalvor"
						            value={hiredSalvorObject.value}
						            error={hiredSalvorObject.error}
						            items={hiredSalvorObject.items}
						            inline={true}
						            onChange={handleHiredSalvorChange}/>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<RadioGroup label={TranslationService.getTranslation('/reportdamageformpage/step2/vehicleatworkshop')}
						            id="vehicleatWorkshop"
						            value={vehicleatWorkshopObject.value}
						            error={vehicleatWorkshopObject.error}
						            items={vehicleatWorkshopObject.items}
						            inline={true}
						            onChange={handleVehicleatWorkshopChange}/>
					</div>
				</div>

				{!_.isEmpty(vehicleatWorkshopObject.value) && !!+vehicleatWorkshopObject.value &&
					<div className="row">
						<div className="col-12">
							<Input label={TranslationService.getTranslation('/reportdamageformpage/step2/workshop')}
									id="workshop"
									value={workshopObject.value}
									error={workshopObject.error}
									onChange={handleWorkshopChange}
									onValidate={handleWorkshopValidation}/>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default VehicleWorkingComponentView;