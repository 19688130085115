﻿import React, { Component } from 'react';

// Lodash functions
let isEmpty = require('lodash/isEmpty');
let isUndefined = require('lodash/isUndefined');

const _ = {
	isEmpty: isEmpty,
	isUndefined: isUndefined,
};

const defaultProps = {
	text: 'label_text',
	id: '',
	value: false,
	error: ''
};

class Checkbox extends Component {

	onKeyPress = (e) => {
		if (this.props.onKeyPress && e.key === 'Enter') {
			this.props.onKeyPress(e);
		}
	};

	render() {
		const {value, id, text, error, helpText} = this.props;

		return (
			<div className={'form-group form-check mb-0' + (!_.isUndefined(error) && !_.isEmpty(error) ? ' has-danger' : '')}>
				<input type="checkbox"
				         className="form-check-input"
				         id={id}
				         name={id}
				         checked={value}
				         onChange={this.props.onChange}
				         onKeyPress={this.onKeyPress}/>
				<label className={`form-check-label`} htmlFor={id}>{text}</label>
				{!_.isEmpty(helpText) &&
					<small className="text-muted form-text mt-05">{helpText}</small>
				}
				{(!_.isUndefined(error) && !_.isEmpty(error)) &&
					<small className="field-validation-error mt-1">{error}</small>
				}
			</div>
		);
	}
}

Checkbox.defaultProps = defaultProps;

export default Checkbox;