﻿import React from 'react';
import ReactDOM from 'react-dom';

import ReportDamageBarkerComponent from "./components/reportDamageBarkerComponent";

if (document.getElementById('report-damage-barker-app')) {
	ReactDOM.render(
		<ReportDamageBarkerComponent/>,
		document.getElementById('report-damage-barker-app')
	);
}